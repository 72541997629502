import { INumberService } from './../../../shared/numberService';
import { IPageStartService } from './../../../shared/pageStartService';
import { IPlanningStatusService } from './../../../shared/planningStatusService';
import { IUserService } from './../../../shared/userService';

import { ITranslationService } from './../../i18n/translationService';

import { INotificationService } from './../../login/notificationService';

import { IPermissionService } from './../../permissions/permissionService';

import { Planboard } from './../../planboard/entities/planboard';
import { ActivityType } from './../../planboard/entities/activitytype';
import * as Globals from './../../planboard/utils/globals';
import { ObjectList } from './../../planboard/utils/objectlist';
import { TimeSpan } from './../../planboard/utils/timespan';

import { ResourceType } from './../../programManagement/resourceTypes/resourceType';
import { OrganizationUnit } from './../../programManagement/organizationUnits/organizationUnit';
import { ResourcePropertyName } from './../../programManagement/resourceProperties/resourcePropertyName';

import { EntityTreeHelpers } from './../../treeListController/EntityTreeHelpers';
import { TreeEntity } from './../../treeListController/TreeEntity';
import { TreeListController } from './../../treeListController/TreeListController';
import { ITreeListScope } from './../../treeListController/ITreeListScope';
import { VerificationStatus } from './../../treeListController/TreeListScope';

import * as Constants from './../../utils/constants';
import { Dictionary } from './../../utils/dictionary';
import { IModalConfirmationWindowService } from './../../utils/modalConfirmationWindowService';
import * as Timezone from './../../utils/timezone';

export var activityTypesCtrl = [
    "$scope", "$http", "$state", "$window", "$timeout", "$filter", "translationService", "permissionService", "modalConfirmationWindowService", "numberService", "userService", "$sce", "pageStartService", "configurationService",
    function ($scope, $http, $state, $window, $timeout, $filter, translationService, permissionService, modalConfirmationWindowService, numberService, userService, $sce, pageStartService, configurationService) {
        translationService.getTextLabels($scope);

        var urlActivityTypes = "api/ActivityTypes";
        var urlActivityTypesMultipleUpdate = "/Multiple";
        var urlGetActivityTypeCategories = urlActivityTypes + "/Categories";
        var urlGetMainActivityTypes = urlActivityTypes + "/MainActivityTypes";
        var urlGetResourceTypes = "api/ResourceTypes";
        var urlGetSkills = "api/Skills";
        var urlGetUserGroups = "api/UserGroups/Names";
        var urlGetOrganizationUnits = "api/OrganizationUnits";
        var urlGetActivityTypeStructure = urlActivityTypes + "/Group/{0}";
        var urlDeleteActivityType = urlActivityTypes + "/{0}";
        var urlCloneActivityType = urlActivityTypes + "/Clone";
        var urlDeleteWorkloadRule = urlActivityTypes + "/WorkloadRule/{0}";
        var urlLoadWorkloadRules = urlActivityTypes + "/{0}/WorkloadRules";
        var urlSaveWorkloadRules = urlActivityTypes + "/WorkloadRule";
        var urlSetSortOrder = urlActivityTypes + "/SetSortOrder";
        var urlSwapSortOrder = urlActivityTypes + "/SwapSortOrder";
        var urlGetOwsActivityTypeMapping = "api/OwsInterface/ForActivityTypeManagement/OwsActivityTypeMapping"

        var dialogToken = "atInfo";
        var automaticSaveDelay = 5000;

        var commonSvc = pageStartService.initialize($scope, null, dialogToken); // create an object that handles common functions and dialogs

        var navbar = $("#mainHeader");
        var dimmedDiv = $("#dimmedDiv");
        var childViewsDiv = $("#actTypeChildViews");
        var actTree = $("#activityTypesTreeContainer");
        var actDetails = $("#elActivityDetails");
        var mainContainer = $("#mainContainer");
        var titlePanel = $("#titlePanel");
        var panelContent = $("#panelContent");

        $scope.verificationStatus = {
            pending: true, // to prevent screen flickering
            failed: false,
            hasPermission: false
        };

        $scope.dataPresent = false;
        $scope.childViewVisible = false;
        $scope.planRulesVisible = false;
        $scope.featureOwsEnabled = false;

        $scope.clearActivityTypes = function () {
            $scope.activityType = []; // activityType Id -> activityType object
            $scope.activityTypesTreeFiltered = []; // root nodes for main activities tree where the selected filter has been applied
            $scope.activityTypesTree = []; // root nodes for main activities tree
            $scope.activityCategories = []; // category Id -> node in activityTypesTree
            $scope.categoryDropdownTree = Object.create(null); // activityCategories for dropdownTree
            $scope.selectedTypeTree = []; // root node for selected activities tree
            $scope.selectedTypeHash = []; // activityType Id -> node in selectedTypeTree
            $scope.selectedMainNode = null;
            $scope.selectedNode = null;
            $scope.autoSelectNodeId = -1;
            $scope.writeEnabled = false;
            $scope.deleteEnabled = false;
            $scope.workloadHours = null; // string representation of the workloadHours for the selected activitytype
            $scope.owsActivityTypeMappings = [];
        };

        $scope.clearActivityTypes();
        $scope.activityTypeFilter = "";
        $scope.toggleUserGroupsMore = true;

        $scope.resourceTypes = Object.create(null); // all resourceTypes
        $scope.skills = Object.create(null); // all skills
        $scope.organizationUnits = Object.create(null); // all organizationUnits
        $scope.userGroups = []; // all usergroups in tree form with nodes

        $scope.changedIds = []; // all activitytype ids that have recently been changed
        $scope.changedCount = 0;

        $scope.planRules = []; // all planrules for the selected activitytype
        $scope.planRuleFreeId = -1; // unique identifier for clientside newly added planrules, will be decreased everytime a rule is added
        $scope.selectedRule = Object.create(null); // currently selected rule is nothing
        $scope.ruleParamListTypes = []; // all parameter type of the selected rule
        $scope.ruleParamListLabels = []; // all parameter labels of the selected rule
        $scope.ruleParamListValues = []; // all parameter values of the selected rule
        $scope.ruleParamListValuesText = []; // all parameter values as text for the selected rule
        $scope.ruleResourceTypes = null; // resource types selectable for rules
        $scope.ruleTimeRanges = null; // time ranges selectable for rules
        $scope.ruleTypes = null; // all possible rule types

        var selectedUnitSetting = "activityTypes.selectedOrganizationUnit";
        var includeChildOrganizationUnitsSetting = "activityTypes.includeChildUnits";
        var includePastDateActivityTypesSetting = "activityTypes.includePastDates";
        var includeFutureDateActivityTypesSetting = "activityTypes.includeFutureDates";

        $scope.filterOrganizationUnitId = -1;
        $scope.filterOnChildOrganizationUnits = false;
        $scope.pastFilterOnActivityTypes = false;
        $scope.futureFilterOnActivityTypes = false;
        $scope.organizationUnitParentChilds = null; // dictionary with parent child relations for organization units

        var activityTypesLoaded = false;
        var activityCategoriesLoaded = false;
        var organizationUnitsLoaded = false;

        var saveChangesTimer = null;
        var saveChangesTimerRunning = false;
        var pendingWebApiRequests = 0;
        var selectedNodeCategoryChanged = false;
        var originalNodeCategory = null;
        var selectedNodeLeavesToSaveOnCategoryChanged = [];
        var originalResourceTypeIds = [];

        /**
         * Hides the pending WebAPI operation information dialog.
         */
        function hidePendingOperationInfo() {
            if (pendingWebApiRequests > 0) pendingWebApiRequests--;
            if (pendingWebApiRequests <= 0)
                modalConfirmationWindowService.closeModalWindow(dialogToken);
            //console.log("Activity types pending WebAPI requests counter: ", pendingWebApiRequests);
        }

        /**
         * Show the pending WebAPI operation information dialog.
         */
        function showPendingOperationInfo(title, text) {
            pendingWebApiRequests++;
            modalConfirmationWindowService.showModalInfoDialog(title, text, "", null, Constants.modalWaitDelay, dialogToken);
        }

        /**
         * Creates a shallow copy of sourceObject to destenationObject
         */
        var shallowCopyObject = function (sourceObject, destenationObject) {
            if (destenationObject == null) destenationObject = Object.create(null);
            for (var key in sourceObject)
                if (sourceObject.hasOwnProperty == undefined || sourceObject.hasOwnProperty(key))
                    if (sourceObject[key] !== destenationObject) // do not copy self reference
                        destenationObject[key] = sourceObject[key];
            return destenationObject;
        }

        /**
         * Called to display the error message from an http response.
         * @param response http response to display the error message from.
         * @param buttonAction Action executed after dismissing the dialog.
         */
        var httpErrorResponse = function (response, buttonAction) {
            hidePendingOperationInfo();
            var errorMessage = translationService.translateErrorMessage(response);
            modalConfirmationWindowService
                .showModalInfoDialog($scope.textLabels.ERROR_OCCURRED,
                    errorMessage,
                    $scope.textLabels.OK,
                    buttonAction,
                    0,
                    dialogToken);
        }

        /**
         * Called to restore an item to the state in the database after a failed update attempt
         */
        var restoreItem = function (itemId) {
            showPendingOperationInfo($scope.textLabels.GETTING_DATA_TITLE, $scope.textLabels.GETTING_DATA_TEXT);

            $http.get(urlActivityTypes + "/" + itemId)
                .then(
                    function (response) {
                        var actType = response.data;
                        actType.updateFailed = false;
                        if (actType.validFrom) actType.validFrom = Timezone.correctTimeZoneInfo(actType.validFrom);
                        if (actType.validTo) actType.validTo = Timezone.correctTimeZoneInfo(actType.validTo);
                        $scope.writeEnabled = true;
                        $scope.deleteEnabled = !(response.data.inUse);
                        addActivityTypeToTree(actType.categoryId, actType, true);
                        hidePendingOperationInfo();
                    },
                    function (response) {
                        // TODO
                        hidePendingOperationInfo();
                    });
        }

        /**
         * Called right before the item is saved (sent to the webapi).
         */
        var sanitizeValues = function (act) {
            act.validFrom = Timezone.rollDateForWebApi(act.validFrom);
            act.validTo = Timezone.rollDateForWebApi(act.validTo);
            act.workloadHours = numberService.toFloat(act.workloadHours);
            act.workloadPartsOfDay = numberService.toFloat(act.workloadPartsOfDay);
        }

        /**
         * Called when the scope is destroyed or when a timer calls it.
         */
        var saveChanges = function (calledFromTimer) {
            if (saveChangesTimerRunning && !calledFromTimer) {
                $timeout.cancel(saveChangesTimer);
                //console.log("save changes timer cancelled.");
            }
            saveChangesTimerRunning = false;
            if ($scope.changedCount === 0) return;

            // make sure the planboard will reload activity types
            Planboard.clearData();
            //console.log("save changes: " + $scope.changedCount + " activity types have changed.");

            if (selectedNodeCategoryChanged && selectedNodeLeavesToSaveOnCategoryChanged.length > 0) {
                $http.put(urlActivityTypes + urlActivityTypesMultipleUpdate, selectedNodeLeavesToSaveOnCategoryChanged).then(
                    null,
                    function (response) {
                        selectedNodeLeavesToSaveOnCategoryChanged[0].categoryId = originalNodeCategory
                        httpErrorResponse(response, function () { restoreItem(selectedNodeLeavesToSaveOnCategoryChanged[0].id); });
                    });
                selectedNodeCategoryChanged = false;

                return;
            }

            for (var i = $scope.changedCount - 1; i >= 0; i--) {
                var act = $scope.activityType[$scope.changedIds[i]];
                if (act && !act.hasInvalidValues) {
                    sanitizeValues(act);
                    (function (actType) {
                        $http.put(urlActivityTypes, actType).then(
                            function (response) {
                                actType.updateFailed = false;
                                $scope.writeEnabled = true;
                                var updatedDefaultTimeSlotList = response.data.defaultTimeSlotList;
                                $scope.selectedNode.actType.defaultTimeSlotList = updatedDefaultTimeSlotList;
                                userService.setLogoffWaitTime(dialogToken + actType.id, 0);
                            },
                            function (response) {
                                actType.updateFailed = true;
                                $scope.writeEnabled = false;
                                httpErrorResponse(response, function () { restoreItem(actType.id); });
                            });
                    })(act);

                    if (act.hasChildrenModifications) {
                        act.hasChildrenModifications = false;

                        var children = [];
                        $scope.activityType.forEach(function (element, index, array) {
                            if (element.parentId == act.id)
                                children.push(element);
                        });

                        if (children.length > 0)
                            $http.put(urlActivityTypes + urlActivityTypesMultipleUpdate, children);
                    }

                    act.changed = false;
                }
            }

            $scope.changedCount = 0;
        }

        /**
         * Helper function to recursively build a filter dictionary.
         */
        var addOrganizationUnitToFilter = function (filterDict, unitId) {
            filterDict.add(unitId, unitId);
            var childIdList = $scope.organizationUnitParentChilds.value(unitId);
            if (childIdList != null && childIdList.length > 0)
                for (var i = 0; i < childIdList.length; i++)
                    addOrganizationUnitToFilter(filterDict, childIdList[i]);
        }

        /**
         * Triggers when the user makes a different filter selection
         */
        $scope.filterOrganizationUnitChangedTimed = function (itemId) {
            $timeout(function () { $scope.filterOrganizationUnitChanged(itemId); }, 0);
        }

        var getValidityState = function (activityType) {
            var validFrom = Timezone.correctTimeZoneInfo(activityType.validFrom);
            var validTo = Timezone.correctTimeZoneInfo(activityType.validTo);
            var currentDate = new Date();

            if (validFrom > currentDate)
                return "futureItem";
            else if (validTo && validTo < currentDate)
                return "pastItem";

            return "validItem";
        }

        /**
         * Called when an organization unit is selected in the filter dropdown.
         */
        $scope.filterOrganizationUnitChanged = function (itemId) {
            if (itemId == null || itemId < 1) itemId = $scope.filterOrganizationUnitId; // use $scope.filterOrganizationUnitId if itemId not valid
            if (itemId == null || itemId < 1) return; // still not valid, exit function
            if (!activityTypesLoaded || !activityCategoriesLoaded || !organizationUnitsLoaded) return; // not all required data is loaded yet

            // Deselect activity type.
            $scope.selectedMainNode = null;
            $scope.selectedNode = null;

            // Save new selection to user settings.
            userService.setDisplaySettingNumber(selectedUnitSetting, itemId);
            userService.setDisplaySettingSwitch(includeChildOrganizationUnitsSetting, $scope.filterOnChildOrganizationUnits);
            userService.setDisplaySettingSwitch(includePastDateActivityTypesSetting, $scope.pastFilterOnActivityTypes);
            userService.setDisplaySettingSwitch(includeFutureDateActivityTypesSetting, $scope.futureFilterOnActivityTypes);

            // applying the filter in a timeout so the view has time to update the selection change
            $timeout(function () {
                // create a filter dictionary of selected organization unit ids
                var orgUnits = new Dictionary();
                orgUnits.add(itemId, itemId);
                if ($scope.filterOnChildOrganizationUnits && $scope.organizationUnitParentChilds != null)
                    addOrganizationUnitToFilter(orgUnits, itemId);

                var filteredTree = [];
                // copy all categories. categories are the top level elements in the activityTypesTree
                for (var i = 0; i < $scope.activityTypesTree.length; i++) {
                    var orgCategory = $scope.activityTypesTree[i];
                    var copiedCategory = shallowCopyObject(orgCategory, undefined);
                    copiedCategory.nodes = [];
                    filteredTree.push(copiedCategory);
                    // add all activity types that have a match with an organization unit in the orgUnits filter (the match is only on root activity type)
                    if (orgCategory.nodes && orgCategory.nodes.length > 0)
                        for (var j = 0; j < orgCategory.nodes.length; j++) {
                            var actType = orgCategory.nodes[j].actType;
                            if (actType && orgUnits.value(actType.ownerOrganizationUnitId) != null) {
                                if (getValidityState(actType) == "futureItem" && !$scope.futureFilterOnActivityTypes || getValidityState(actType) == "pastItem" && !$scope.pastFilterOnActivityTypes)
                                    continue;
                                copiedCategory.nodes.push(orgCategory.nodes[j]);
                            }
                        }
                }

                $scope.activityTypesTreeFiltered = filteredTree;
            }, 200);
        }

        /**
         * Called when a new activitytype is added to a category.
         */
        $scope.addToCategory = function (node) {
            var act = {
                categoryId: node.id,
                displayName: node.displayName,
                shortName: node.displayName,
                textColor: "000000",
                backColor: "ffffff",
                ownerOrganizationUnitId: $scope.filterOrganizationUnitId
            };

            showPendingOperationInfo($scope.textLabels.ADDING_DATA_TITLE, $scope.textLabels.ADDING_DATA_TEXT);

            (function (actType) {
                $http.put(urlActivityTypes, actType)
                    .then(
                        function (response) {
                            hidePendingOperationInfo();

                            if (response && response.data && response.data.id) {
                                var newNode = addActivityTypeToTree(response.data.categoryId, response.data, false);
                                $scope.selectCategoryClick(newNode);
                            } else
                                $scope.refreshData();
                        },
                        function (response) {
                            hidePendingOperationInfo();

                            // stop using the remembered lastUsedUnitId
                            userService.setDisplaySettingNumber("activityTypes.lastUnit", -1);

                            modalConfirmationWindowService.closeModalWindow(dialogToken);
                            modalConfirmationWindowService.showModalInfoDialog(
                                $scope.textLabels.ERROR_OCCURRED,
                                translationService.translateErrorMessage(response),
                                $scope.textLabels.OK,
                                function () { $scope.refreshData(); },
                                Constants.modalWaitDelay,
                                dialogToken);
                        });
            })(act);
        }

        /**
         * Called when a new leaf activitytype is added to a parent activitytype.
         */
        $scope.addToStructure = function (node) {
            // First, check if we need to set sort order to tree
            if (shouldSetActivityTypeOrder()) {
                setActivityTypeOrder(function () { return $scope.addToStructure(node); });
                return;
            }
            // Calculate sort order
            var newSortOrder = 1; // default to 1 if no siblings
            var nodeChildren = $scope.selectedTypeHash[node.id].nodes;
            var childrenSortOrders = [];
            if (nodeChildren.length > 0) {
                for (var i = 0; i < nodeChildren.length; i++)
                    childrenSortOrders.push(parseInt(nodeChildren[i].actType.sortOrder, 10));
                newSortOrder = Math.max.apply(null, childrenSortOrders) + 1;
            }

            ////
            var act = {
                parentId: node.id,
                categoryId: node.actType.categoryId,
                displayName: $scope.textLabels.DISPLAY_NAME,
                shortName: $scope.textLabels.ACTIVITY_SHORT,
                userGroupPermissionList: node.actType.userGroupPermissionList,
                textColor: "000000",
                backColor: "ffffff",
                ownerOrganizationUnitId: node.actType.ownerOrganizationUnitId,
                sortOrder: newSortOrder
            };

            showPendingOperationInfo($scope.textLabels.ADDING_DATA_TITLE, $scope.textLabels.ADDING_DATA_TEXT);

            (function (actType) {
                $http.put(urlActivityTypes, actType)
                    .then(
                        function (response) {
                            hidePendingOperationInfo();

                            if (response && response.data && response.data.id) {
                                // make sure this new node will be selected the next refresh
                                $scope.autoSelectNodeId = response.data.id;
                                // if no new parent was created by this put (same id as known id), then we can insert the new leaf imediately at the correct position
                                if (response.data.parentId && response.data.parentId === actType.parentId) {
                                    $scope.selectedNode = addSelectedActivityType(response.data); // and also select it
                                }
                                // reselect the main activity type so that we will get the most up to date version
                                $scope.selectCategoryClick($scope.selectedMainNode);
                            } else
                                $scope.refreshData();
                        },
                        function (response) {
                            hidePendingOperationInfo();

                            modalConfirmationWindowService.closeModalWindow(dialogToken);
                            modalConfirmationWindowService.showModalInfoDialog(
                                $scope.textLabels.ERROR_OCCURRED,
                                translationService.translateErrorMessage(response),
                                $scope.textLabels.OK,
                                function () { $scope.refreshData(); },
                                Constants.modalWaitDelay,
                                dialogToken);
                        });
            })(act);
        }

        /**
         * Filter input on text to only accept numeric values.
         */
        $scope.filterTextValue = function ($event, oldValue, allowDecimal) {
            numberService.filterTextValue($event, oldValue, allowDecimal);
        };

        /**
         * Add an activity type to the list of changed activity types.
         */
        $scope.addChange = function (activityTypeId) {
            if (!$scope.writeEnabled) return;

            var found = false;
            for (var i = $scope.changedCount - 1; i >= 0; i--)
                if ($scope.changedIds[i] === activityTypeId) {
                    found = true;
                    break;
                }

            if (!found) {
                $scope.changedIds[$scope.changedCount] = activityTypeId;
                $scope.changedCount++;
            }

            var act = $scope.activityType[activityTypeId];
            if (act) act.changed = true;

            if ($scope.featureOwsEnabled) {
                var mappedActivityTypeIds = $scope.owsActivityTypeMappings.map((item) => item.activityTypeId);
                if (act.resourceTypeIdList.length === 0 && mappedActivityTypeIds.some((obj) => { return obj === activityTypeId })) {
                    if (saveChangesTimerRunning) {
                        $timeout.cancel(saveChangesTimer);
                        saveChangesTimerRunning = false;
                    }

                    modalConfirmationWindowService
                        .showModalDialog($scope.textLabels.ACTIVITY_TYPE_DELETE_RESOURCETYPE_MODAL_TITLE,
                            $scope.textLabels.ACTIVITY_TYPE_DELETE_RESOURCETYPE_MODAL_TEXT,
                            function () {
                                $scope.activateSaveChangeTimer(activityTypeId);
                            },
                            function () {
                                $scope.selectedMainNode.actType.resourceTypeIdList = originalResourceTypeIds.slice();
                            });
                } else {
                    $scope.activateSaveChangeTimer(activityTypeId)
                }
            } else {
                $scope.activateSaveChangeTimer(activityTypeId)
            }
        }

        /**
        * (re-)activate savechanges timer
        */
        $scope.activateSaveChangeTimer = function (activityTypeId) {
            // copy string representations of floating point numbers to the actual activitytype object
            userService.setLogoffWaitTime(dialogToken + activityTypeId, automaticSaveDelay);
            if (saveChangesTimerRunning) $timeout.cancel(saveChangesTimer);
            saveChangesTimer = $timeout(function () { saveChanges(true); }, automaticSaveDelay);
            saveChangesTimerRunning = true;
        }

        /**
         * Callback event when the user changes something about the activitytype.
         */
        $scope.onActivityTypeChanged = function () {
            // copy string representations of floating point numbers to the actual activitytype object
            var actType = $scope.selectedNode.actType;
            actType.workloadHours = numberService.toFloat($scope.workloadHours);
            $scope.addChange($scope.selectedNode.id);
            $scope.selectedNode.displayClassName = getValidityState(actType);
        }

        /**
         * Callback event when the user changes the owner organization unit of an activitytype.
         */
        $scope.onActivityTypeOwnerChanged = function (itemId) {
            userService.setDisplaySettingNumber("activityTypes.lastUnit", itemId);
            $scope.onActivityTypeChanged();
        }

        /**
        * Event handler that checks if the selected dates are valid upon change of the from date.
        * @param date The from date.
        */
        $scope.onFromDateChanged = function (date) {
            var actType = $scope.selectedNode.actType;

            actType.hasInvalidValues = date && actType.validTo && date > actType.validTo;
            actType.validFrom = date;
            $scope.onActivityTypeChanged();
        }

        /**
         * Event handler that checks if the selected dates are valid upon change of the to date.
         * @param date The to date.
         */
        $scope.onToDateChanged = function (date) {
            var actType = $scope.selectedNode.actType;

            actType.hasInvalidValues = actType.validFrom && date && actType.validFrom > date;
            actType.validTo = date;
            $scope.onActivityTypeChanged();
        }

        /**
         * Callback event when the user changes the category of the activitytype.
         * @param itemId The id of the category
         */
        $scope.onCategoryChanged = function (itemId) {
            if (!$scope.writeEnabled || !$scope.selectedNode || !$scope.selectedNode.actType) return;
            if ($scope.nodeHasParent($scope.selectedNode)) return; // only category of main nodes may be changed

            var actTypeAddedToTree = Object.create($scope.selectedNode);
            addActivityTypeToTree(itemId, actTypeAddedToTree, true);

            selectedNodeLeavesToSaveOnCategoryChanged = [];
            $scope.updateNodeChildrenActivityTypeCategory($scope.selectedNode, itemId);

            originalNodeCategory = $scope.selectedNode.actType.categoryId;
            selectedNodeCategoryChanged = true;

            $scope.onActivityTypeChanged();
        }

        /**
         * Function called to delete an activity type with after showing a confirmation dialog.
         */
        $scope.confirmRemoveActivityType = function () {
            modalConfirmationWindowService
                .showModalDialog($scope.textLabels.ACTIVITY_TYPE_DELETION_MODAL_TITLE,
                    $scope.textLabels.ACTIVITY_TYPE_DELETION_MODAL_TEXT,
                    $scope.removeActivityType,
                    null);
        }

        /**
         * Function called to delete an activity type.
         */
        $scope.removeActivityType = function () {
            if (!$scope.writeEnabled || !$scope.selectedNode || !$scope.selectedNode.actType) return;

            showPendingOperationInfo($scope.textLabels.DELETING_DATA_TITLE, $scope.textLabels.DELETING_DATA_TEXT);

            $http.delete(Globals.stringFormat(urlDeleteActivityType, [$scope.selectedNode.id]))
                .then(
                    function (response) {
                        hidePendingOperationInfo();
                        modalConfirmationWindowService.closeModalWindow(dialogToken);
                    },
                    function (response) {
                        hidePendingOperationInfo();

                        modalConfirmationWindowService.closeModalWindow(dialogToken);
                        modalConfirmationWindowService.showModalInfoDialog(
                            $scope.textLabels.ERROR_OCCURRED,
                            translationService.translateErrorMessage(response),
                            $scope.textLabels.OK,
                            function () { $scope.refreshData(); },
                            Constants.modalWaitDelay,
                            dialogToken);
                    });

            // remove the activitytype from memory
            removeActivityTypeFromTree($scope.selectedNode.id, -1);
            if ($scope.selectedNode.id === $scope.selectedMainNode.id) $scope.selectedMainNode = null;
            $scope.selectedNode = $scope.selectedMainNode;
        }

        /**
         * Clones the activity type tree associated with the supplied activity type.
         * @param {} activityType Activity type from the activity type tree to clone.
         */
        $scope.cloneSelectedActivityType = function () {
            var selectedType = $scope.selectedTypeTree[0].actType;
            //console.log(selectedType);

            // First, save any pending changes to prevent the old state of an entity to be cloned.
            saveChanges(false);

            var cloneRequest = {
                id: selectedType.id,
                displayName: selectedType.displayName + " (" + $scope.textLabels.COPY + ")"
            }

            showPendingOperationInfo($scope.textLabels.GETTING_DATA_TITLE, $scope.textLabels.GETTING_DATA_TEXT);

            $http.post(urlCloneActivityType, cloneRequest)
                .then(function (response) {
                    // Add the clone to the proper category subtree
                    //console.log(response.data);
                    addActivityTypeToTree(response.data.categoryId, response.data, false);

                    hidePendingOperationInfo();
                    modalConfirmationWindowService.closeModalWindow(dialogToken);
                },
                    function (response) {
                        hidePendingOperationInfo();

                        modalConfirmationWindowService.closeModalWindow(dialogToken);
                        modalConfirmationWindowService.showModalInfoDialog(
                            $scope.textLabels.ERROR_OCCURRED,
                            translationService.translateErrorMessage(response),
                            $scope.textLabels.OK,
                            function () { $scope.refreshData(); },
                            Constants.modalWaitDelay,
                            dialogToken);
                    });
        }

        /**
         * Called when the browser window resizes.
         */
        var doResizeActivity = function () {
            //$timeout(function () {
            // padding directly below the tree on the inside of titlePanel's panel-body, we divide by 2 to get just the bottom padding
            //var paddingBottom = Math.floor((actTree.parent().innerHeight() - actTree.parent().height()) / 2) + 1;
            var paddingBottom = Math.floor((panelContent.innerHeight() - panelContent.height()) / 2) + 1;
            // height of elements below the titlePanel, including shadow
            var bottomHeight = (mainContainer.offset().top + mainContainer.outerHeight()) - (titlePanel.offset().top + titlePanel.outerHeight());
            // maximum height the tree can be to fit inside visible area
            var maxHeight = $window.innerHeight - (actTree.offset().top + bottomHeight + paddingBottom);
            // minimum height
            var minHeight = bottomHeight * 2;
            maxHeight = Math.floor(Math.max(maxHeight, minHeight)); // make sure it is not too small
            actTree.css("max-height", "" + maxHeight + "px");
            if (actDetails.offset().left > 0 && actDetails.offset().top > 0) {
                if (actDetails.offset().left <= actTree.offset().left + actTree.width())
                    minHeight = 65536; // page to small
                maxHeight = $window.innerHeight - (actDetails.offset().top + bottomHeight + paddingBottom);
                maxHeight = Math.floor(Math.max(maxHeight, minHeight)); // make sure it is not too small
            }
            actDetails.css("max-height", "" + maxHeight + "px");
            // position dimmed div just under the navbar (dimmed is normally full screen)
            var navBarHeight = navbar.outerHeight(false);
            var dimmedHeight = Math.floor(Math.max(window.innerHeight - navBarHeight, 0));
            dimmedDiv.css("top", "" + navBarHeight + "px");
            dimmedDiv.css("height", "" + dimmedHeight + "px");
            // set maximum height of child views
            var childViewsTop = childViewsDiv.position().top;
            childViewsDiv.css("max-height", "" + Math.floor(Math.max(dimmedHeight - childViewsTop * 2, 0)) + "px");
            //}, 0);
        }

        /**
        * Gets the indentation for a node in the permission tree.
        * The indentation consists of repeating a character multiple times, depending on the tree level op the node.
        * @param level
        */
        $scope.getPermissionTreeIndent = function (level) {
            var result = "";
            for (let i = 0; i < level; i++) result = result + "\u00A0\u00A0"; // nicely indent two spaces here
            return result;
        }

        /**
         * Called when the user changes something about the usergroup permissions.
         */
        $scope.toggleUserGroupPermission = function (userGroupId, permissionNumber, shouldPropagateCheckbox, affectChildren) {
            if (affectChildren == null && $scope.selectedNode.nodes.length > 0) {
                commonSvc.showYesNoDialog($scope.textLabels.AFFECT_CHILDREN, $scope.textLabels.AFFECT_CHILDREN_QUESTION, function () {
                    $scope.toggleUserGroupPermission(userGroupId, permissionNumber, shouldPropagateCheckbox, true);
                },
                    function () {
                        $scope.toggleUserGroupPermission(userGroupId, permissionNumber, shouldPropagateCheckbox, false);
                    });
            }
            else {
                if (!$scope.writeEnabled || !userGroupId || !$scope.selectedNode || !$scope.selectedNode.actType) return;
                var permissionList = $scope.selectedNode.actType.userGroupPermissionList;
                if (!permissionList) {
                    permissionList = [];
                    $scope.selectedNode.actType.userGroupPermissionList = permissionList;
                }
                // find previous highest permission and remove all permissions for this userGroup
                var oldValue = $scope.removeUserGroupPermissions(permissionList, userGroupId);
                var newValue = 0;

                if (permissionNumber < 2 && oldValue > 1) oldValue = 1;
                // we are not toggling write, so treat any previous permission higher than read as read
                if (permissionNumber >= 2 && oldValue < 2) { // currently toggling write and previous permission was lower than write
                    newValue = 2; // set to read&write
                } else if (oldValue !== 1) { // previous permission was other than read
                    newValue = 1; // set to read
                }
                permissionList.push({ permission: newValue, userGroupId: userGroupId });

                // Propagate down the newly set value.
                if (shouldPropagateCheckbox)
                    updateUserGroupChildrenPermissions(userGroupId, permissionList, newValue);

                // Copy the modified permission to the leaves as well
                if (affectChildren) {
                    updateNodeChildrenPermissions($scope.selectedNode, userGroupId, newValue, shouldPropagateCheckbox);
                    $scope.selectedNode.actType.hasChildrenModifications = true;
                }

                $scope.onActivityTypeChanged();
            }
        }

        $scope.updateNodeChildrenActivityTypeCategory = function (node, value) {
            var childrenNodes = node.nodes;
            node.categoryId = value;
            selectedNodeLeavesToSaveOnCategoryChanged.push($scope.activityType[node.actType.id]);

            if (childrenNodes && childrenNodes.length > 0) {
                for (var i = 0; i < childrenNodes.length; i++) {
                    childrenNodes[i].actType.categoryId = value;

                    if (childrenNodes[i].nodes && childrenNodes[i].nodes.length > 0) {
                        $scope.updateNodeChildrenActivityTypeCategory(childrenNodes[i], value);
                    } else {
                        selectedNodeLeavesToSaveOnCategoryChanged.push($scope.activityType[childrenNodes[i].actType.id]);
                    }
                }
            }
        }

        // Set a permission to a certain value for all the leaves of a node
        var updateNodeChildrenPermissions = function (node, userGroupId, value, shouldPropagateCheckbox) {
            var childrenNodes = node.nodes;
            if (childrenNodes.length > 0) {
                for (var i = 0; i < childrenNodes.length; i++) {
                    $scope.removeUserGroupPermissions(childrenNodes[i].actType.userGroupPermissionList, userGroupId);
                    childrenNodes[i].actType.userGroupPermissionList.push({ permission: value, userGroupId: userGroupId });
                    if (shouldPropagateCheckbox)
                        updateUserGroupChildrenPermissions(userGroupId, childrenNodes[i].actType.userGroupPermissionList, value);
                }
            }
        }

        // Set all of a permission's children to the same value
        var updateUserGroupChildrenPermissions = function (userGroupId, permissionList, value) {
            var userGroupsDict = $scope.userGroupsDict;
            if (userGroupsDict) {
                var group = userGroupsDict.value(userGroupId);
                if (group && group.nodes) {
                    for (var i = 0; i < group.nodes.length; i++) {
                        $scope.setUserGroupPermission(group.nodes[i], permissionList, value);
                    }
                }
            }
        }

        /**
         * Recursively sets (not toggles) a permission value for a user group in a user group permission list.
         * @param {} group User group to set the value for.
         * @param {} permissionList Permission list to set the value in.
         * @param {} value Value to set. 0 or smaller is not set, instead an existing permission value is just removed.
         */
        $scope.setUserGroupPermission = function (group, permissionList, value) {
            // Remove old value (if any).
            $scope.removeUserGroupPermissions(permissionList, group.id);
            // Set new value.
            if (value && value > 0) {
                permissionList.push({ permission: value, userGroupId: group.id });
            }
            // Recursively apply to children.
            if (group.nodes) {
                for (var i = 0; i < group.nodes.length; i++) {
                    $scope.setUserGroupPermission(group.nodes[i], permissionList, value);
                }
            }
        }

        /**
         * Removes all permissions for the given user group from the specified permission list and returns the maximum permission value given to the specified user group.
         * @param {} permissionList Permission list to remove the permissions from.
         * @param {} userGroup Use group to remove the permissions for.
         */
        $scope.removeUserGroupPermissions = function (permissionList, userGroupId) {
            var oldValue = 0;
            var index = permissionList.length;
            while (index > 0) {
                index--;
                if (permissionList[index].userGroupId === userGroupId) {
                    oldValue = Math.max(oldValue, permissionList[index].permission);
                    permissionList.splice(index, 1);
                }
            }

            return oldValue;
        }

        /**
         * Get the highest permission level for the currently selected activitytype for a specific usergroup
         */
        $scope.getActUserGroupPermission = function (userGroup) {
            if (!userGroup || !$scope.selectedNode || !$scope.selectedNode.actType) return 0;
            var permissionList = $scope.selectedNode.actType.userGroupPermissionList;
            var highestPermission = 0;
            if (permissionList)
                for (var i = 0; i < permissionList.length; i++)
                    if (permissionList[i].userGroupId === userGroup.id)
                        highestPermission = Math.max(highestPermission, permissionList[i].permission);
            return highestPermission;
        }

        /**
         * Called when the user adds a new default timeslot to the selected activitytype.
         */
        $scope.addTimeSlot = function () {
            if (!$scope.writeEnabled || !$scope.selectedNode || !$scope.selectedNode.actType) return;
            if (!$scope.selectedNode.actType.defaultTimeSlotList)
                $scope.selectedNode.actType.defaultTimeSlotList = [];
            var timeSlotList = $scope.selectedNode.actType.defaultTimeSlotList;
            timeSlotList.push({ id: 0, startMinutes: 0, endMinutes: 0 });
        }

        /**
         * Called when the user removes a default timeslot from the selected activitytype.
         */
        $scope.removeTimeSlot = function (index) {
            if (!$scope.writeEnabled || !$scope.selectedNode || !$scope.selectedNode.actType) return;
            var timeSlotList = $scope.selectedNode.actType.defaultTimeSlotList;
            if (!timeSlotList) return;
            if (index < 0 || index >= timeSlotList.length) return;
            timeSlotList.splice(index, 1);
            $scope.onActivityTypeChanged();
        }

        /**
         * Callback when the value for a default time changes.
         */
        $scope.defaultTimeChanged = function (id, value) {
            if (!$scope.writeEnabled || !$scope.selectedNode || !$scope.selectedNode.actType) return;
            var timeSlotList = $scope.selectedNode.actType.defaultTimeSlotList;
            if (!timeSlotList) return;
            var index = -1;
            var beginOrEnd = 0;
            if (id.substring(0, 10) === "begintime-") {
                index = parseInt(id.substring(10));
                beginOrEnd = 1;
            }
            if (id.substring(0, 8) === "endtime-") {
                index = parseInt(id.substring(8));
                beginOrEnd = 2;
            }
            if (beginOrEnd < 1 || index < 0 || index >= timeSlotList.length) return;
            var splitPos = value.indexOf(":");
            if (splitPos < 0) splitPos = value.length;
            var minValue = -1;
            if (value !== "") {
                var hourPart = parseInt("0" + value.substring(0, splitPos));
                var minutesPart = parseInt("0" + value.substring(splitPos + 1));
                minValue = (hourPart * 60) + minutesPart;
            }
            if (beginOrEnd === 1) timeSlotList[index].startMinutes = minValue;
            if (beginOrEnd === 2) timeSlotList[index].endMinutes = minValue;
            $scope.onActivityTypeChanged();
        }

        /**
         * Convert a numberic number of minutes to a string respresentation of hours and minutes.
         */
        $scope.minutesToStr = function (minutes) {
            var minValue = parseInt(minutes);
            if (minValue < 0) return "";
            var hourPart = Math.floor(minValue / 60);
            var minutePart = minValue - (hourPart * 60);
            var result = "" +
                (hourPart < 10 ? "0" + hourPart : "" + hourPart) +
                ":" +
                (minutePart < 10 ? "0" + minutePart : "" + minutePart);
            return result;
        }

        /**
         * Returns if a node should be hidden because it doesn't match a filter.
         */
        $scope.hideNode = function (node) {
            if ($scope.activityTypeFilter === "") return false; // no filter
            if (node.nodes && node.nodes.length > 0) return false; // nodes with children never hidden
            var filter = $scope.activityTypeFilter.trim().toLowerCase(); // filter to lower
            if (node.actType.displayName.trim().toLowerCase().indexOf(filter) >= 0) return false; // matches displayname
            if (node.actType.shortName.trim().toLowerCase().indexOf(filter) >= 0) return false; // matches shortname
            return true;
        }

        /**
         * Returns if a skill should be visible (and selectable) for the activitytype.
         */
        $scope.isSkillVisible = function (skillId) {
            if (!$scope.selectedNode ||
                !$scope.selectedNode.actType ||
                !$scope.selectedNode.actType.resourceTypeIdList) return false;
            return $scope.isSkillApplicable(skillId, $scope.selectedNode.actType.resourceTypeIdList);
        }


        /**
         * Returns if a skill is applicable for any of the selected resourceType ids.
         */
        $scope.isSkillApplicable = function (skillId, selResourceTypeIds) {
            var skill = $scope.skills[skillId];
            if (!skill) return false;
            var skillResourceTypeIdList = skill.validResourceTypeIds;
            if (!skillResourceTypeIdList || !selResourceTypeIds) return false;
            var max = Math.min(skillResourceTypeIdList.length, selResourceTypeIds.length);
            while (max > 0) {
                max--;
                if (selResourceTypeIds.indexOf(skillResourceTypeIdList[max]) >= 0) return true;
                if (skillResourceTypeIdList.indexOf(selResourceTypeIds[max]) >= 0) return true;
            }
            return false;
        }

        /**
         * Returns if a node in a tree has child nodes.
         */
        $scope.nodeHasLeaf = function (node) {
            if (!node && $scope.selectedNode) // use the selected node instead
                return $scope.nodeHasLeaf($scope.selectedNode);
            if (node && node.nodes)
                return node.nodes.length > 0;
            return true; // default value true so that leaf configurations will be hidden
        }

        /**
         * Returns if a node in a tree has child nodes.
         */
        $scope.nodeIsSingleConfiguredActivityType = function (node) {
            if (!node && $scope.selectedNode) // use the selected node instead
                return $scope.nodeIsSingleConfiguredActivityType($scope.selectedNode);
            if (node && node.nodes)
                return node.nodes.length === 0 && node.actType.parentId === null;
            return false; // default value false so that the not required field is enabled
        }

        /**
         * Returns if a node in a tree has a parent.
         */
        $scope.nodeHasParent = function (node) {
            if (!node && $scope.selectedNode) // use the selected node instead
                return $scope.nodeHasParent($scope.selectedNode);
            return (node && node.actType && node.actType.parentId && node.actType.parentId >= 0);
        }

        /**
        * Returns if an activity is a daymark based on its categoryId.
        */

        $scope.nodeIsDaymark = function (node) {
            if (!node && $scope.selectedNode) // use the selected node instead
                return $scope.nodeHasParent($scope.selectedNode);
            return (node && node.actType && node.actType.categoryId === 4);
        }
        /**
        * Returns if the checkbox for resource types it s disabled.
        */

        $scope.isCheckboxForResourceTypesDisabled = function () {
            return $scope.nodeIsDaymark($scope.selectedNode) || $scope.nodeIsSingleConfiguredActivityType($scope.selectedNode);
        };
        /**
         * Returns if a node in a tree has a configuration that is specific for a leaf node, e.g. resourceTypes or skills
         */
        $scope.nodeHasLeafConfiguration = function (node) {
            if (!node && $scope.selectedNode) // use the selected node instead
                return $scope.nodeHasLeafConfiguration($scope.selectedNode);
            if (node && node.actType) {
                // selected resourceTypes?
                if (node.actType.resourceTypeIdList && node.actType.resourceTypeIdList.length > 0) return true;
                // selected skills?
                if (node.actType.skillIdList && node.actType.skillIdList.length > 0)
                    for (var i = 0; i < node.actType.skillIdList.length; i++)
                        if (node.actType.skillIdList[i] && node.actType.skillIdList[i].length > 0) return true;
            }
            return false;
        }

        /**
         * Start resize event handler.
         */
        $(window).on("resize.doResizeActivity", doResizeActivity);

        /**
         * Remove resize event handler.
         */
        $scope.$on("$destroy", function () {
            $scope.savePlanRules(); // save any changes to plan rules
            saveChanges(false);
            $(window).off("resize.doResizeActivity");
        });

        /**
         * Not sure if this is used, there is a reference in activityTypesView.html
         */
        $scope.treeOptions = {
            accept: function (sourceNodeScope, destNodesScope, destIndex) {
                return false;
            },
            removed: function (node) {
            }
        };

        /**
         * Get all activitytypes and categories from the webapi.
         */
        $scope.getActivityTypes = function () {
            activityTypesLoaded = false;
            activityCategoriesLoaded = false;

            // First, clear the currently loaded activity type data.
            $scope.clearActivityTypes();

            pendingWebApiRequests++;
            $http.get(urlGetActivityTypeCategories)
                .then(function (response) {
                    hidePendingOperationInfo();
                    doResizeActivity();
                    for (var i = 0, len = response.data.length; i < len; i++) {

                        response.data[i].displayName = $scope.textLabels[response.data[i].jsonName];

                        addCategoryToTree(response.data[i].id, response.data[i].displayName);
                    }

                    activityCategoriesLoaded = true;
                    $scope.filterOrganizationUnitChanged();
                },
                    function (response) { httpErrorResponse(response, null) });

            pendingWebApiRequests++;
            $http.get(urlGetMainActivityTypes)
                .then(function (response) {
                    hidePendingOperationInfo();
                    doResizeActivity();
                    for (var i = 0, len = response.data.length; i < len; i++)
                        addActivityTypeToTree(response.data[i].categoryId, response.data[i], false);
                    activityTypesLoaded = true;
                    $scope.filterOrganizationUnitChanged();
                },
                    function (response) { httpErrorResponse(response, null) });
        }

        /**
         * Get all resourcetypes from the webapi.
         */
        $scope.getResourceTypes = function () {
            pendingWebApiRequests++;
            $http.get(urlGetResourceTypes)
                .then(function (response) {
                    hidePendingOperationInfo();
                    $scope.resourceTypes = Object.create(null);

                    var orderedResourceTypes = $filter("orderBy")(response.data, "displayName");

                    for (var i = 0, len = orderedResourceTypes.length; i < len; i++) {
                        $scope.resourceTypes[orderedResourceTypes[i].id] = {
                            displayName: orderedResourceTypes[i].displayName,
                            order: i
                        };
                    }
                },
                    function (response) { httpErrorResponse(response, null) });
        }

        /**
         * Get all skills from the webapi.
         */
        $scope.getSkills = function () {
            pendingWebApiRequests++;
            $http.get(urlGetSkills)
                .then(function (response) {
                    hidePendingOperationInfo();
                    $scope.skills = Object.create(null);

                    var orderedSkills = $filter("orderBy")(response.data, "displayName");

                    for (var i = 0, len = orderedSkills.length; i < len; i++) {
                        orderedSkills[i].order = i;
                        $scope.skills[orderedSkills[i].id] = orderedSkills[i];
                    }
                },
                    function (response) { httpErrorResponse(response, null) });
        }

        /**
         * Get all usergroups from the webapi.
         */
        $scope.getUserGroups = function () {
            pendingWebApiRequests++;
            $http.get(urlGetUserGroups)
                .then(function (response) {
                    hidePendingOperationInfo();

                    // Step 1: load into dictionary.
                    $scope.userGroupsDict = new Dictionary();
                    //var userGroupsSorted = $filter("orderBy")(response.data, "displayName");
                    for (var i = 0; i < response.data.length; i++) {
                        response.data[i].receivedOrder = i + 1; // received order of 0 should be at least 0
                        $scope.userGroupsDict.add(response.data[i].id, response.data[i]);
                    }

                    // Step 2: get array of root level nodes.
                    $scope.userGroups = EntityTreeHelpers
                        .entityDictionaryToTree($scope.userGroupsDict,
                            $scope,
                            function () { },
                            EntityTreeHelpers.sortLastEntity);
                },
                    function (response) { httpErrorResponse(response, null) });
        }

        /**
         * Returns whether the user has write permission on the currently selected activity type. Write permission means:
         * owner permission on the activity type itself and its parent and write permission on the activity type's owner organization unit.
         */
        $scope.hasActWritePermission = function () {
            if (!$scope.selectedNode || !$scope.selectedNode.actType || !$scope.organizationUnits) return false;
            var ownerUnitId = $scope.selectedNode.actType.ownerOrganizationUnitId;
            /*console.log("hasActWritePermission()",
                $scope.selectedNode.actType,
                $scope.organizationUnits[ownerUnitId]);*/
            if ($scope.organizationUnits[ownerUnitId] == undefined) return false;

            var writePermissionByParent = true;
            if ($scope.selectedNode.actType.parentId) {
                var parentType = $scope.activityType[$scope.selectedNode.actType.parentId];
                if (!parentType) writePermissionByParent = false;
            }

            return writePermissionByParent && $scope.organizationUnits[ownerUnitId].userHasWritePermission;
        }

        /**
         * Get all organizationunits from the webapi.
         */
        $scope.getOrganizationUnits = function () {
            organizationUnitsLoaded = false;
            pendingWebApiRequests++;
            $http.get(urlGetOrganizationUnits)
                .then(function (response) {
                    hidePendingOperationInfo();
                    $scope.organizationUnits = Object.create(null);
                    $scope.organizationUnitParentChilds = new Dictionary();
                    var sortedOrganizationUnits = $filter("orderBy")(response.data, "displayName");
                    for (var i = 0, len = sortedOrganizationUnits.length; i < len; i++) {
                        var organizationUnit = sortedOrganizationUnits[i];
                        organizationUnit.order = i;
                        var permission = organizationUnit.maxPermissionForCurrentUser;
                        organizationUnit.userHasWritePermission = false;
                        if (!$scope.organizationUnits[organizationUnit.id])
                            $scope.organizationUnits[organizationUnit.id] = organizationUnit;
                        else
                            organizationUnit = $scope.organizationUnits[organizationUnit.id];
                        if (permission >= 2) organizationUnit.userHasWritePermission = true;
                        organizationUnit.updateFailed = false;
                        // add to the organizationUnitParentChilds dictionary
                        if (organizationUnit.parentId != null && organizationUnit.parentId > 0) {
                            var childIdList = $scope.organizationUnitParentChilds.value(organizationUnit.parentId);
                            if (childIdList == null) {
                                childIdList = [];
                                $scope.organizationUnitParentChilds.add(organizationUnit.parentId, childIdList);
                            }
                            childIdList.push(organizationUnit.id);
                        }
                    }
                    // get filter settings
                    $scope.filterOrganizationUnitId = userService.getDisplaySettingNumber(selectedUnitSetting, 0);
                    $scope.filterOnChildOrganizationUnits = userService.getDisplaySettingSwitch(includeChildOrganizationUnitsSetting);
                    $scope.pastFilterOnActivityTypes = userService.getDisplaySettingSwitch(includePastDateActivityTypesSetting);
                    $scope.futureFilterOnActivityTypes = userService.getDisplaySettingSwitch(includeFutureDateActivityTypesSetting);
                    // activate the filter
                    organizationUnitsLoaded = true;
                    $scope.filterOrganizationUnitChanged();
                },
                    function (response) { httpErrorResponse(response, null) });
        }

        /**
         * Get all activitytypes that are mapped
         */
        $scope.getOwsActivityTypeMappings = function () {
            pendingWebApiRequests++;

            // Get Feature Flag OWS
            configurationService.getAppConfiguration(function () {
                $scope.featureOwsEnabled = configurationService.appConfiguration.featureManagement.featureEnableOWS;

                // Only if OWS is enabled we should get the mapped activity types
                if ($scope.featureOwsEnabled) {
                    $http.get(urlGetOwsActivityTypeMapping)
                        .then(function (response) {
                            hidePendingOperationInfo();

                            $scope.owsActivityTypeMappings = response.data;
                        },
                            function (response) { httpErrorResponse(response, null) });
                } else {
                    // No extra call is made so the pending operation can be hidden again
                    hidePendingOperationInfo();
                }
            });
        }

        /**
         * Refresh all data.
         */
        $scope.refreshData = function () {
            pendingWebApiRequests = 0;
            modalConfirmationWindowService.showModalInfoDialog(
                $scope.textLabels.GETTING_DATA_TITLE,
                $scope.textLabels.GETTING_DATA_TEXT, "", null, Constants.modalWaitDelay, dialogToken);

            $scope.getActivityTypes();
            $scope.getResourceTypes();
            $scope.getSkills();
            $scope.getOrganizationUnits();
            $scope.getUserGroups();
            $scope.getOwsActivityTypeMappings();
        }

        /**
         * See whether the currenly logged in user has the "ActivityTypes" permission and set flags accordingly.
         */
        permissionService.userHasPermission("ActivityTypes", $scope.verificationStatus, $scope).then(function () {
            if ($scope.verificationStatus.hasPermission) {
                $scope.refreshData();
            }
        });

        /**
         * Called when the user selects an activitytype from either the categories tree or the activitytype structure tree.
         */
        $scope.showDetails = function (node) {
        }

        /**
         * Called when an activitytype is clicked in the activitytype structure tree.
         */
        $scope.selectClick = function (node) {
            if (!node.selectable) return;

            // the select html element requires that the selected value is a string, so we convert the number to a string.
            if (node.actType.workloadCalculationMode)
                node.actType.workloadCalculationMode = node.actType.workloadCalculationMode.toString();

            // copy floating point numbers to string representations
            $scope.workloadHours = numberService.toFloatStr(node.actType.workloadHours);

            $scope.selectedNode = node;
            $scope.writeEnabled = $scope.hasActWritePermission() && !node.actType.updateFailed;
            $scope.deleteEnabled = !node.actType.inUse;
            $scope.showDetails(node);
        }

        /**
         * Called when an activitytype is clicked in the categories tree.
         */
        $scope.selectCategoryClick = function (node) {
            if (!node.selectable) return;
            $scope.selectedMainNode = node;
            //$scope.selectedNode = null;
            //$scope.selectedTypeTree = [];
            //$scope.selectedTypeHash = [];
            showPendingOperationInfo($scope.textLabels.GETTING_DATA_TITLE, $scope.textLabels.GETTING_DATA_TEXT);

            $http.get(Globals.stringFormat(urlGetActivityTypeStructure, [node.id]))
                .then(function (response) {
                    hidePendingOperationInfo();
                    $scope.selectedTypeTree = [];
                    $scope.selectedTypeHash = [];
                    var selectAddedNode = null;
                    var firstAddedNode = null;
                    var selectSpecificNode = null;
                    for (var i = 0, len = response.data.length; i < len; i++) {
                        var addedNode = addSelectedActivityType(response.data[i]);
                        if (!firstAddedNode) firstAddedNode = addedNode;
                        if (addedNode.id === $scope.selectedMainNode.id) selectAddedNode = addedNode;
                        if (addedNode.id === $scope.autoSelectNodeId) selectSpecificNode = addedNode;
                    }
                    if (!selectAddedNode && firstAddedNode) {
                        // the main activitytype has changed, replace it with the newly received one
                        selectAddedNode = firstAddedNode;
                        $scope.selectedMainNode.id = selectAddedNode.id;
                        $scope.selectedMainNode.actType = selectAddedNode.actType;
                    }
                    $scope.selectedNode = selectAddedNode;
                    if (selectAddedNode.actType.validFrom) selectAddedNode.actType.validFrom = Timezone.correctTimeZoneInfo(selectAddedNode.actType.validFrom);
                    if (selectAddedNode.actType.validTo) selectAddedNode.actType.validTo = Timezone.correctTimeZoneInfo(selectAddedNode.actType.validTo);
                    if (selectSpecificNode) $scope.selectedNode = selectSpecificNode;
                    $scope.autoSelectNodeId = -1;
                    $scope.selectClick($scope.selectedNode);

                    originalResourceTypeIds = $scope.selectedMainNode.actType.resourceTypeIdList.slice();
                }, function (response) {
                    httpErrorResponse(response, null);
                });
            $scope.showDetails(node);
        }

        /**
         * Returns if a skilllist is the last list.
         */
        $scope.isLastSkillList = function (index) {
            if (!$scope.selectedNode || !$scope.selectedNode.actType || !$scope.selectedNode.actType.skillIdList) return true;
            return index === $scope.selectedNode.actType.skillIdList.length - 1;
        }

        /**
         * Returns if a skilllist is the first list.
         */
        $scope.isFirstSkillList = function (index) {
            return index === 0;
        }

        /**
         * Callback when the user adds a new skill list.
         */
        $scope.addSkillList = function () {
            if (!$scope.selectedNode || !$scope.selectedNode.actType) return;
            if (!$scope.selectedNode.actType.skillIdList) $scope.selectedNode.actType.skillIdList = [];
            $scope.selectedNode.actType.skillIdList.push([]);
        }

        /**
         * returns if a childview is visible, making the dimmed div visible
         */
        $scope.getChildViewVisible = function () {
            return $scope.childViewVisible ? "visible" : "hidden";
        }

        /**
         * hide the planrules div
         */
        $scope.closePlanRules = function () {
            $scope.savePlanRules(); // save any changes to plan rules
            $scope.childViewVisible = false;
            $scope.planRulesVisible = false;
            $scope.selectedRule = Object.create(null); // select no rule
            $scope.ruleParamListTypes = []; // no rule parameters visible
            $scope.planRules = []; // no plan rules loaded
        }

        /**
         * make the planrules div visible
         */
        $scope.showPlanRules = function () {
            $scope.initializeRuleTypes();
            $scope.initializeRuleResourceTypes();
            $scope.initializeRuleTimeRanges();
            $scope.loadPlanRules();
            $scope.planRulesVisible = true;
            $scope.childViewVisible = true;
            $timeout(function () { childViewsDiv[0].focus(); }, 100); // remove focus from button
        }

        /**
         * when clicked on the dimmed div, go back from the child view to the parent view
         */
        $scope.closeChildView = function ($event) {
            if ($event && $event.target && $event.target.id === "dimmedDiv") $scope.closePlanRules();
            $event.stopPropagation();
        }

        /**
         * returns if a rule is the selected rule
         */
        $scope.isSelectedRule = function (rule) {
            if (rule && $scope.selectedRule) return rule.id === $scope.selectedRule.id;
            return false;
        }

        /**
         * parse a rule and return flat text
         * @param rule the rule to parse
         * @param paramListTypes output array of parameter types (cast to lowerCace)
         * @param paramListLabels output array of parameter labels
         * @param paramListValues output array of parameter values
         * @param paramListValuesText output array of parameter values as text
         * @returns flat text of the rule
         */
        $scope.parseRule = function (rule, paramListTypes, paramListLabels, paramListValues, paramListValuesText) {
            if (!$scope.planRulesVisible) return "";
            var text = $scope.textLabels["PLANNING_RULE_TYPE_" + rule.ruleType.toString()];
            while (text != undefined && text !== "") {
                var startPos = text.indexOf("[");
                var endPos = text.indexOf("]");
                if (startPos < 0 || endPos < 0 || endPos < startPos) return text;
                var tag = text.substring(startPos, endPos + 1);
                var separatorPos = tag.indexOf(":");
                var tagType = tag.substring(1, separatorPos > 0 ? separatorPos : tag.length - 1).toLowerCase();
                var tagLabel = separatorPos > 0 ? tag.substring(separatorPos + 1, tag.length - 1) : "";
                var tagValue = null, tagValueText = null;

                // colored tag --- skipping this now that we broke it into another function
                if (tagType.length > 3 && tagType.substring(0, 4) === "tag-") {
                    text = text.substring(0, startPos) + text.substring(endPos + 1);
                    continue;
                }

                // note: tagType is cast to lowerCase
                switch (tagType) {
                    case "hours":
                        tagValue = rule.hours;
                        break;
                    case "ranges":
                        tagValue = rule.ranges;
                        break;
                    case "resourcetype":
                        tagValue = rule.resourceTypeId;
                        var resourceType = tagValue && $scope.ruleResourceTypes ? $scope.ruleResourceTypes[tagValue.toString()] : null;
                        tagValueText = resourceType ? resourceType.text : null;
                        break;
                    case "integerparameter1":
                        tagValue = rule.integerParameter1;
                        break;
                    case "integerparameter2":
                        tagValue = rule.integerParameter2;
                        break;
                    case "timerangetype":
                        tagValue = rule.timeRangeType;
                        var timeRangeType = tagValue && $scope.ruleTimeRanges ? $scope.ruleTimeRanges[tagValue.toString()] : null;
                        tagValueText = timeRangeType ? timeRangeType.text : null;
                        break;
                    case "activitytype":
                        tagValue = rule.activityTypeId;
                        if ($scope.selectedNode && $scope.selectedNode.actType)
                            tagValueText = $scope.selectedNode.actType.displayName;
                        break;
                    default:
                        tagValue = tagValueText = "";
                }
                if (tagValueText == null && tagValue != null) tagValueText = tagValue.toString();
                if ((tagValueText == null || tagValueText === "") && (tagValue == null || tagValue === ""))
                    tagValueText = $scope.textLabels.NO_SELECTION;

                if (paramListTypes) paramListTypes.push(tagType);
                if (paramListLabels) paramListLabels.push(tagLabel);
                if (paramListValues) paramListValues.push(tagValue);
                if (paramListValuesText) paramListValuesText.push(tagValueText);
                text = text.substring(0, startPos) + "<b>" + tagValueText + "</b>" + text.substring(endPos + 1);
            }
            return "";
        }

        /**
         * returns tag color of given rule
         * TODO: rework needed in future if we want to display multiple tags
         */
        $scope.getTagColor = function (rule) {
            var color = "transparent"; // default if no tag

            if (!$scope.planRulesVisible) return "";
            var text = $scope.textLabels["PLANNING_RULE_TYPE_" + rule.ruleType.toString()];

            var startPos = text.indexOf("[");
            var endPos = text.indexOf("]");
            if (startPos < 0 || endPos < 0 || endPos < startPos) return text;
            var tag = text.substring(startPos, endPos + 1);
            var separatorPos = tag.indexOf(":");
            var tagType = tag.substring(1, separatorPos > 0 ? separatorPos : tag.length - 1).toLowerCase();

            // colored tag
            if (tagType.length > 3 && tagType.substring(0, 4) === "tag-") {
                color = tagType.substring(4);
            }

            return color;
        }

        /**
         * returns tag text of given rule
         * TODO: rework needed in future if we want to display multiple tags
         */
        $scope.getTagText = function (rule) {
            if (!$scope.planRulesVisible) return "";
            var text = $scope.textLabels["PLANNING_RULE_TYPE_" + rule.ruleType.toString()];
            var tagLabel = "";

            var startPos = text.indexOf("[");
            var endPos = text.indexOf("]");
            if (startPos < 0 || endPos < 0 || endPos < startPos) return text;
            var tag = text.substring(startPos, endPos + 1);
            var separatorPos = tag.indexOf(":");
            var tagType = tag.substring(1, separatorPos > 0 ? separatorPos : tag.length - 1).toLowerCase();

            // colored tag
            if (tagType.length > 3 && tagType.substring(0, 4) === "tag-") {
                tagLabel = separatorPos > 0 ? tag.substring(separatorPos + 1, tag.length - 1) : "";
            }

            return tagLabel;
        }

        /**
         * get the index of a parameterType
         * @param parameterType parameter to get the index for
         * @returns index or -1 if nothing was found
         */
        $scope.getParameterTypeIndex = function (parameterType) {
            if (!$scope.planRulesVisible) return -1;
            return $scope.ruleParamListTypes.indexOf(parameterType.toLowerCase());
        }

        /**
         * initialize the dropdownlist for rule time ranges
         */
        $scope.initializeRuleTimeRanges = function () {
            if ($scope.ruleTimeRanges != null) return;
            $scope.ruleTimeRanges = Object.create(null);
            $scope.ruleTimeRanges["1"] = { id: 1, text: $scope.textLabels.TIME_RANGE_TYPE_1, enabled: true };
            $scope.ruleTimeRanges["2"] = { id: 2, text: $scope.textLabels.TIME_RANGE_TYPE_2, enabled: true };
        }

        /**
         * initialize the dropdownlist for rule types
         */
        $scope.initializeRuleTypes = function () {
            if ($scope.ruleTypes != null) return;
            $scope.ruleTypes = Object.create(null);
            $scope.ruleTypes["1"] = { id: 1, text: $scope.textLabels.PLANNING_RULE_NAME_1, enabled: true };
            $scope.ruleTypes["2"] = { id: 2, text: $scope.textLabels.PLANNING_RULE_NAME_2, enabled: true };
            $scope.ruleTypes["3"] = { id: 3, text: $scope.textLabels.PLANNING_RULE_NAME_3, enabled: true };
            $scope.ruleTypes["4"] = { id: 4, text: $scope.textLabels.PLANNING_RULE_NAME_4, enabled: true };
        }

        /**
         * initialize the dropdownlist for rule resource types
         */
        $scope.initializeRuleResourceTypes = function () {
            var key = "";
            // create list if it is null
            if ($scope.ruleResourceTypes == null) {
                $scope.ruleResourceTypes = Object.create(null);
                for (key in $scope.resourceTypes)
                    $scope.ruleResourceTypes[key] = {
                        id: Number(key),
                        text: $scope.resourceTypes[key].displayName,
                        enabled: true
                    };
            }
            // set what resourceTypes are selectable
            if ($scope.selectedNode &&
                $scope.selectedNode.actType &&
                $scope.selectedNode.actType.resourceTypeIdList) {
                // initialize all to false
                for (key in $scope.ruleResourceTypes) $scope.ruleResourceTypes[key].enabled = false;
                // enable all selected resourceTypes for this activityType
                for (var i = 0; i < $scope.selectedNode.actType.resourceTypeIdList.length; i++) {
                    key = $scope.selectedNode.actType.resourceTypeIdList[i].toString();
                    if ($scope.ruleResourceTypes[key])
                        $scope.ruleResourceTypes[key].enabled = true;
                    else // add just in case it was not in $scope.resourceTypes initially
                        $scope.ruleResourceTypes[key] = {
                            id: Number(key),
                            text: $scope.resourceTypes[key].displayName,
                            enabled: true
                        };
                }
            }
        }

        /**
         * select a rule
         */
        $scope.selectRule = function (rule) {
            var paramListTypes = [];
            var paramListLabels = [];
            var paramListValues = [];
            var paramListValuesText = [];
            $scope.parseRule(rule, paramListTypes, paramListLabels, paramListValues, paramListValuesText);
            $scope.ruleParamListTypes = paramListTypes;
            $scope.ruleParamListLabels = paramListLabels;
            $scope.ruleParamListValues = paramListValues;
            $scope.ruleParamListValuesText = paramListValuesText;
            $scope.selectedRule = rule;
        }

        /**
         * when the rule type changes we need to parse the rule again
         */
        $scope.onRuleTypeChanged = function (itemId) {
            if (!$scope.planRulesVisible || !$scope.selectedRule || !$scope.selectedRule.id) return;
            $scope.selectedRule.ruleType = itemId;
            $scope.selectRule($scope.selectedRule);
        }

        /**
         * get the flat text of a rule
         */
        $scope.getRuleText = function (rule) {
            var text = $scope.parseRule(rule, null, null, null);
            return text === "" ? null : $sce.trustAsHtml(text);
        }

        /**
         * Returns the text to display in datepicker for clearing a date.
         */
        $scope.getClearDateText = function () {
            return $scope.textLabels.NO_DATE;
        }

        /**
         * remove a planning rule
         */
        $scope.removePlanRule = function (rule) {
            modalConfirmationWindowService
                .showModalDialog($scope.textLabels.PLANNING_RULE_DELETION_TITLE,
                    $scope.textLabels.PLANNING_RULE_DELETION_TEXT,
                    function () {
                        // send webApi call to remove this rule
                        if (rule.id > 0) {
                            showPendingOperationInfo($scope.textLabels.DELETING_DATA_TITLE, $scope.textLabels.DELETING_DATA_TEXT);
                            $http.delete(Globals.stringFormat(urlDeleteWorkloadRule, [rule.id]))
                                .then(
                                    function (response) { hidePendingOperationInfo(); },
                                    function (response) { httpErrorResponse(response, null); });
                        }
                        // remove from array $scope.planRules
                        for (var i = $scope.planRules.length - 1; i >= 0; i--)
                            if ($scope.planRules[i].id === rule.id)
                                $scope.planRules.splice(i, 1);
                        $scope.selectedRule = Object.create(null); // select no rule
                        $scope.ruleParamListTypes = []; // no rule parameters visible
                    },
                    null);
        }

        /**
         * return if changes have been made to a rule
         */
        $scope.ruleHasChanges = function (rule) {
            if (rule.id < 0 || rule.originalRule == null) return true;
            for (var key in rule.originalRule) {
                if (!rule.originalRule.hasOwnProperty || rule.originalRule.hasOwnProperty(key)) {
                    var value1 = rule[key];
                    var value2 = rule.originalRule[key];
                    if (value1 === value2) continue; // identical
                    if (value1 === rule.originalRule) continue; // do not test self reference
                    if (value1 != null && value2 != null) {
                        // one of the two is a string and the other is something else, test if they are the same number
                        if (value1.charAt && !value2.charAt && Number(value1) === value2) continue;
                        if (value2.charAt && !value1.charAt && Number(value2) === value1) continue;
                    }
                    return true;
                }
            }
            return false;
        }

        /**
         * get all planRules for an activity type from the webApi.
         */
        $scope.loadPlanRules = function () {
            if (!$scope.selectedNode || !$scope.selectedNode.actType) return;

            showPendingOperationInfo($scope.textLabels.GETTING_DATA_TITLE, $scope.textLabels.GETTING_DATA_TEXT);
            $http.get(Globals.stringFormat(urlLoadWorkloadRules, [$scope.selectedNode.actType.id]))
                .then(function (response) {
                    hidePendingOperationInfo();
                    var loadedRules = [];
                    for (var i = 0, len = response.data.length; i < len; i++) {
                        var rule = response.data[i];
                        rule.originalRule = shallowCopyObject(rule, rule.originalRule);
                        loadedRules.push(rule);
                    }
                    $scope.planRules = loadedRules;
                },
                    function (response) { httpErrorResponse(response, null) });
        }

        /**
         * post webApi calls to save changed plan rules
         */
        $scope.savePlanRules = function () {
            for (var i = 0; i < $scope.planRules.length; i++) {
                var rule = $scope.planRules[i];
                if ($scope.ruleHasChanges(rule)) {
                    // copy new values to orginal rule, so it will not be saved again
                    rule.originalRule = shallowCopyObject(rule, rule.originalRule);

                    // create post data object
                    var fromDate = rule.validFrom;
                    var toDate = rule.validTo;
                    if (fromDate && fromDate.getTime) fromDate = new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60000).toISOString();
                    if (toDate && toDate.getTime) toDate = new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60000).toISOString();
                    var postData = {
                        id: rule.id < 0 ? -1 : rule.id,
                        ruleType: rule.ruleType,
                        activityTypeId: rule.activityTypeId,
                        resourceTypeId: rule.resourceTypeId,
                        hours: rule.hours,
                        timeRangeType: rule.timeRangeType,
                        ranges: rule.ranges,
                        integerParameter1: rule.integerParameter1,
                        integerParameter2: rule.integerParameter2,
                        validFrom: fromDate,
                        validTo: toDate
                    }

                    showPendingOperationInfo($scope.textLabels.ADDING_DATA_TITLE, $scope.textLabels.ADDING_DATA_TEXT);
                    (function (workloadRule) {
                        $http.put(urlSaveWorkloadRules, workloadRule)
                            .then(
                                function (response) {
                                    hidePendingOperationInfo();
                                    // replace id with actual saved id
                                    if (response && response.data && response.data.id && workloadRule.id < 0) {
                                        for (var j = 0; j < $scope.planRules.length; j++)
                                            if ($scope.planRules[j].id === workloadRule.id)
                                                $scope.planRules[j].id = response.data.id;
                                    }
                                },
                                function (response) {
                                    httpErrorResponse(response, function () { });
                                });
                    })(postData);
                }
            }
        }

        /**
         * add a new planning rule
         */
        $scope.addPlanRule = function () {
            if (!$scope.selectedNode || !$scope.selectedNode.actType) return; // can not make a rule without activityType

            // find a valid resource type for this activity
            var resourceTypeIdValue = $scope.selectedNode.actType.resourceTypeIdList &&
                $scope.selectedNode.actType.resourceTypeIdList.length > 0
                ? $scope.selectedNode.actType.resourceTypeIdList[0] : null;

            // find unused ruleType
            var ruleTypeValue = 1;
            for (var i = 0; i < $scope.planRules.length; i++) ruleTypeValue = Math.min($scope.planRules[i].ruleType + 1, 4);

            var rule = {
                id: $scope.planRuleFreeId--,
                activityTypeId: $scope.selectedNode.actType.id,
                ruleType: ruleTypeValue,
                validFrom: null,
                validTo: null,
                resourceTypeId: resourceTypeIdValue,
                ranges: null,
                timeRangeType: 1,
                integerParameter1: null,
                integerParameter2: null,
                originalRule: null
            }
            $scope.planRules.push(rule); // add new rule to array of rules
            $scope.selectRule(rule); // select the new rule
        }

        /**
         * Returns if the node has sibling nodes
         * @param node the currently selected node
         */
        $scope.hasSiblings = function (node) {
            var parent = $scope.selectedTypeHash[node.actType.parentId];
            if (!parent) return false; // root
            if (parent.nodes.length > 0)
                return true;
            return false;
        }

        /**
         * Returns if the node can move Up in the list
         * @param node the currently selected node
         */
        $scope.canDecreaseSortOrder = function (node) {
            if (shouldSetActivityTypeOrder()) {
                // must infer from front end data
                if ($scope.hasSiblings(node)) {
                    var siblingsIncludingNode = $scope.selectedTypeHash[node.actType.parentId].nodes;
                    var nodeIndex = -1;
                    for (var i = 0; i < siblingsIncludingNode.length; i++) {
                        if (siblingsIncludingNode[i].id == node.id) {
                            nodeIndex = i;
                            break;
                        }
                    }
                    if (nodeIndex > 0)
                        return true;
                }
                return false;
            }
            // we can rely on sort order
            if ($scope.hasSiblings(node)) {
                var siblingsIncludingNode = $scope.selectedTypeHash[node.actType.parentId].nodes;
                var siblingSortOrders = [];
                for (var i = 0; i < siblingsIncludingNode.length; i++)
                    siblingSortOrders.push(parseInt(siblingsIncludingNode[i].actType.sortOrder, 10));
                if (node.actType.sortOrder != Math.min.apply(null, siblingSortOrders))
                    return true;
            }
            return false;
        }

        /**
         * Returns if the node can move Down in the list
         * @param node the currently selected node
         */
        $scope.canIncreaseSortOrder = function (node) {
            if (shouldSetActivityTypeOrder()) {
                // must infer from front end data
                if ($scope.hasSiblings(node)) {
                    var siblingsIncludingNode = $scope.selectedTypeHash[node.actType.parentId].nodes;
                    var nodeIndex = -1;
                    for (var i = 0; i < siblingsIncludingNode.length; i++) {
                        if (siblingsIncludingNode[i].id == node.id) {
                            nodeIndex = i;
                            break;
                        }
                    }
                    if (nodeIndex > -1 && nodeIndex < siblingsIncludingNode.length - 1)
                        return true;
                }
                return false;
            }
            // we can rely on sort order
            if ($scope.hasSiblings(node)) {
                var siblingsIncludingNode = $scope.selectedTypeHash[node.actType.parentId].nodes;
                var siblingSortOrders = [];
                for (var i = 0; i < siblingsIncludingNode.length; i++)
                    siblingSortOrders.push(parseInt(siblingsIncludingNode[i].actType.sortOrder, 10));
                if (node.actType.sortOrder != Math.max.apply(null, siblingSortOrders))
                    return true;
            }
            return false;
        }

        /**
         * Makes api call to Decrease the sort order
         * Called on Up arrow sort button click
         * @param node the currently selected node
         */
        $scope.decreaseSortOrder = function (node) {
            if (!$scope.selectedMainNode || !$scope.selectedNode || !$scope.canDecreaseSortOrder(node)) return;
            if (shouldSetActivityTypeOrder()) {
                setActivityTypeOrder(function () { return $scope.decreaseSortOrder(node); });
                return;
            }
            var siblingsIncludingNode = $scope.selectedTypeHash[node.actType.parentId].nodes;
            var siblingSortOrders = [];
            var siblingNodesDict = new Dictionary();
            for (var i = 0; i < siblingsIncludingNode.length; i++) {
                var sortOrder = parseInt(siblingsIncludingNode[i].actType.sortOrder, 10);
                siblingSortOrders.push(sortOrder);
                siblingNodesDict.add(siblingsIncludingNode[i].id, siblingsIncludingNode[i]);
            }
            siblingSortOrders.sort(function (a, b) { return a - b });
            var nodeIndex = siblingSortOrders.indexOf(node.actType.sortOrder);
            var swapOrder = siblingSortOrders[nodeIndex - 1];

            var nodeId = node.id;
            var swapId = null;
            var swapNode = null;
            siblingNodesDict.forEach(function (key, value) {
                if (value.actType.sortOrder == swapOrder) {
                    swapId = key;
                    swapNode = value;
                    return;
                }
            });

            var activityIds = [nodeId, swapId];

            commonSvc.post(urlSwapSortOrder,
                activityIds,
                function () {
                    // to update the frontend
                    swapNode.actType.sortOrder = node.actType.sortOrder;
                    node.actType.sortOrder = swapOrder;
                },
                null,
                true);
        }

        /**
         * Makes api call to Increase the sort order
         * Called on Down arrow sort button click
         * @param node the currently selected node
         */
        $scope.increaseSortOrder = function (node) {
            if (!$scope.selectedMainNode || !$scope.selectedNode || !$scope.canIncreaseSortOrder(node)) return;
            if (shouldSetActivityTypeOrder()) {
                setActivityTypeOrder(function () { return $scope.increaseSortOrder(node); });
                return;
            }
            var siblingsIncludingNode = $scope.selectedTypeHash[node.actType.parentId].nodes;
            var siblingSortOrders = [];
            var siblingNodesDict = new Dictionary();
            for (var i = 0; i < siblingsIncludingNode.length; i++) {
                var sortOrder = parseInt(siblingsIncludingNode[i].actType.sortOrder, 10);
                siblingSortOrders.push(sortOrder);
                siblingNodesDict.add(siblingsIncludingNode[i].id, siblingsIncludingNode[i]);
            }
            siblingSortOrders.sort(function (a, b) { return a - b });
            var nodeIndex = siblingSortOrders.indexOf(node.actType.sortOrder);
            var swapOrder = siblingSortOrders[nodeIndex + 1];

            var nodeId = node.id;
            var swapId = null;
            var swapNode = null;
            siblingNodesDict.forEach(function (key, value) {
                if (value.actType.sortOrder == swapOrder) {
                    swapId = key;
                    swapNode = value;
                    return;
                }
            });

            var activityIds = [nodeId, swapId];

            commonSvc.post(urlSwapSortOrder,
                activityIds,
                function () {
                    // to update the frontend
                    swapNode.actType.sortOrder = node.actType.sortOrder;
                    node.actType.sortOrder = swapOrder;
                },
                null,
                true);
        }

        /**
         * Returns if the sort order should be set (if there are null or repeat values)
         */
        function shouldSetActivityTypeOrder() {
            var activitySortOrder = [];
            var shouldSetOrder = false;
            $scope.selectedTypeHash.forEach(
                function (item, id) {
                    if (activitySortOrder.indexOf(item.actType.sortOrder) > -1 || item.actType.sortOrder == null)
                        shouldSetOrder = true;
                    activitySortOrder.push(item.actType.sortOrder);
                });
            return shouldSetOrder;
        }

        /**
         * Makes api call to save the activity type tree order if there are any null or duplicate values
         * @param callBackFunction the function to call after success response from api
         */
        function setActivityTypeOrder(callBackFunction) {
            console.log("set activity type order");
            var activityIds = [];
            var index = 0;
            $scope.selectedTypeHash.forEach(
                function (item, id) {
                    item.actType.sortOrder = index + 1;// temporarily set this until next get or post
                    activityIds[index] = id;
                    index++;
                });
            // SetSortOrder
            commonSvc.post(urlSetSortOrder, activityIds,
                function () {
                    callBackFunction();
                }, null, true);
        }

        /**
         * Update all properties of an activitytype in memory with those of the newAct.
         */
        function updateActivityType(newAct) {
            // make sure that there is at least one skill list
            if (!newAct.skillIdList) newAct.skillIdList = [];
            if (newAct.skillIdList.length === 0) newAct.skillIdList.push([]);

            var oldAct = $scope.activityType[newAct.id]; // find existing item
            if (!oldAct) return newAct; // not found, return newAct
            if (oldAct.changed) return oldAct; // changed by user, do not update any values

            // update values: copy all properties of newAct into oldAct
            for (var key in newAct) {
                if (newAct.hasOwnProperty == undefined || newAct.hasOwnProperty(key)) {
                    oldAct[key] = newAct[key];
                }
            }

            return oldAct; // return the existing item
        }

        /**
         * Add an activitytype to the selectedTypeTree (activitytype structure tree).
         */
        function addSelectedActivityType(a) {
            a = updateActivityType(a);
            a.updateFailed = false;
            var act = {
                nodes: [],
                id: a.id,
                actType: a,
                graphical: true,
                selectable: true
            };
            $scope.activityType[a.id] = a;
            $scope.selectedTypeHash[act.id] = act;
            var p = $scope.selectedTypeHash[a.parentId];
            if (p) {
                p.nodes.push(act);
            } else {
                $scope.selectedTypeTree.push(act);
            }
            return act;
        }

        /**
         * Remove an activitytype from all categories in the tree, exclude one category with a specific id
         * @param actId Id of the activitytype to remove from memory
         * @param excludeCategoryId The id of the category to ignore, the activitytype will not be removed if it is in this category
         */
        function removeActivityTypeFromTree(actId, excludeCategoryId) {
            // remove from parent activitytype
            var index = 0;
            var act = $scope.activityType[actId];
            if (act && act.parentId && act.parentId >= 0) {
                var p = $scope.selectedTypeHash[act.parentId];
                if (p) index = p.nodes.length;
                while (index > 0) {
                    index--;
                    if (p.nodes[index].id === actId) p.nodes.splice(index, 1);
                }
                return; // no need to check the categories, since it was a leaf node
            }
            // remove from categories tree
            for (var i = 0; i < $scope.activityTypesTree.length; i++) {
                var cat = $scope.activityTypesTree[i];
                if (cat.id !== excludeCategoryId) {
                    index = cat.nodes.length;
                    while (index > 0) {
                        index--;
                        if (cat.nodes[index].id === actId) cat.nodes.splice(index, 1);
                    }
                }
            }
            // remove from filtered categories tree
            for (var i = 0; i < $scope.activityTypesTreeFiltered.length; i++) {
                var cat = $scope.activityTypesTreeFiltered[i];
                if (cat.id !== excludeCategoryId) {
                    index = cat.nodes.length;
                    while (index > 0) {
                        index--;
                        if (cat.nodes[index].id === actId) cat.nodes.splice(index, 1);
                    }
                }
            }
        }

        /**
         * Add an activitytype to a category in the tree, return the node of the tree where it was added.
         * @param categoryId The id of the category
         * @param a The activitytype to add
         * @param removeFromOtherCategories Boolean to indicate that the activity should be removed from any other categories
         */
        function addActivityTypeToTree(categoryId, a, removeFromOtherCategories) {
            //console.log("addActivityTypeToTree()", a, $scope.activityType[a.id]);
            var oldAct = $scope.activityType[a.id];
            if (oldAct) a = updateActivityType(a);
            a.updateFailed = false;
            if (removeFromOtherCategories) removeActivityTypeFromTree(a.id, categoryId); // do not remove if it is already in the desired categoryId
            var cat = $scope.activityCategories[categoryId];
            if (!cat) cat = addCategoryToTree(categoryId, "");
            var actNode = {
                nodes: [],
                id: a.id,
                actType: a,
                graphical: true,
                hideButtons: true,
                hideToggle: true,
                selectable: true,
                displayClassName: getValidityState(a)
            };
            $scope.activityType[a.id] = a;

            if (oldAct) // test if it already exists
                for (var i = 0; i < cat.nodes.length; i++)
                    if (cat.nodes[i].id === actNode.id) return cat.nodes[i]; // return existing node
            cat.nodes.push(actNode);

            // also add to filtered categories tree ($scope.activityTypesTreeFiltered)
            if ($scope.activityTypesTreeFiltered && $scope.activityTypesTreeFiltered.length > 0)
                for (var j = 0; j < $scope.activityTypesTreeFiltered.length; j++)
                    if ($scope.activityTypesTreeFiltered[j].id === cat.id)
                        $scope.activityTypesTreeFiltered[j].nodes.push(actNode);

            return actNode; // return new node
        }

        /**
         * Add a category to the tree or replace the displayname for an existing category with the same id.
         */
        function addCategoryToTree(id, displayName) {
            var cat = $scope.activityCategories[id];
            if (cat) {
                if (displayName !== "") cat.displayName = displayName;
            } else {
                cat = {
                    nodes: [],
                    id: id,
                    displayName: displayName
                };
                $scope.activityCategories[id] = cat;
                $scope.activityTypesTree.push(cat);

                // sort by id
                var index = $scope.activityTypesTree.length;
                while (index > 0) {
                    if ($scope.activityTypesTree[index] != undefined &&
                        $scope.activityTypesTree[index - 1] != undefined) {
                        if ($scope.activityTypesTree[index].id < $scope.activityTypesTree[index - 1].id) {
                            var swap = $scope.activityTypesTree[index];
                            $scope.activityTypesTree[index] = $scope.activityTypesTree[index - 1];
                            $scope.activityTypesTree[index - 1] = swap;
                        }
                    }
                    index--;
                }
            }

            $scope.categoryDropdownTree[cat.id] = cat;

            return cat;
        }
    }
];
