import * as angular from 'angular';
import { AuthOptions } from 'auth0-js';
import 'angular-auth0/src';
import * as Constants from './components/utils/constants';
import { StateService, TransitionService, StateProvider, UrlRouterProvider } from '@uirouter/angularjs';
import { IEnvironmentProvider } from './shared/environmentService';
import { IConfigurationService } from './shared/configurationService';

export var routeconfig = [
    "$stateProvider", "$urlRouterProvider",
    function ($stateProvider: StateProvider, $urlRouterProvider: UrlRouterProvider) {

        // For any unmatched url, send to /login
        // werkt niet in Chrome en Firefox, alleen maar in IE, workaround is otherwise state in $stateProvider.
        $urlRouterProvider.otherwise("/login");

        // view and controller for the signIn/signOut button in the navbar
        const loginLogoutLinkView = {
            template: require("./components/login/loginLogoutLink.html"),
            controller: "loginLogoutLinkController",
            controllerAs: "vm"
        };

        // each state will refer to this object in the resolve 
        const retrieveTextLabelsResolve =
            [
                "translationService",
                function (translationService) {
                    return translationService.retrieveTextLabels();
                }
            ];

        // some states will refer to this object in the resolve 
        const retrieveUserDisplaySettingsResolve =
            [
                "userService",
                function (userService) {
                    return userService.retrieveDisplaySettings();
                }
            ];

        // the loginView used by state login and state otherwise
        const loginView = {
            "main": {
                template: require("./components/login/loginView.html"),
                controller: "loginController",
                controllerAs: "vm"
            },
            "loginLogoutLink": loginLogoutLinkView
        };

        $stateProvider
            .state('default', {
                url: '',
                redirectTo: 'portal'
            })
            .state("login",
                {
                    url: "/login",
                    views: loginView,
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve
                    }
                })
            .state("authorize",
                {
                    url: "/authorize",
                    views: {
                        "main": {
                            template: require("./components/login/authorizeView.html"),
                            controller: "authorizeCtrl",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve
                    }
                })
            .state("saveSSOToken",
                {
                    url: "/savessotoken/{token:string}",
                    views: {
                        "main": {
                            template: require("./components/login/saveSSOTokenView.html"),
                            controller: "SaveSSOTokenController"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    }
                })
            .state("portal",
                {
                    url: "/portal",
                    views: {
                        "main": {
                            template: require("./components/portal/portalPageView.html"),
                            controller: "portalPageController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve,
                        retrieveUserDisplaySettings: retrieveUserDisplaySettingsResolve
                    }
                })
            .state("planboard",
                {
                    url: "/planboard",
                    views: {
                        "main": {
                            template: require("./components/planboard/planboardView.html"),
                            controller: "PlanboardCtrl"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve,
                        retrieveUserDisplaySettings: retrieveUserDisplaySettingsResolve
                    }
                })
            .state("planboard.activity",
                {
                    url: "/activity/{activityId:int}",
                    params: {
                        activityId: 0
                    },
                    views: {
                        "activity": {
                            template: require("./components/activityManagement/activityView.html"),
                            controller: "activityController",
                            controllerAs: "vm"
                        }
                    }
                    // resolve is inherited from parent
                })
            .state("planboard.activityPaste",
                {
                    url: "/activityPaste/{activityId:int}",
                    params: {
                        activityId: 0,
                        pasteDate: null,
                        resourceId: 0
                    },
                    views: {
                        "activityPaste": {
                            template: require("./components/activityPaste/activityPasteView.html"),
                            controller: "activityPasteController",
                            controllerAs: "vm"
                        }
                    }
                    // resolve is inherited from parent
                })
            .state("planboard.replaceResource",
                {
                    url: "/replaceResource/{activityId:int}",
                    params: {
                        activityId: 0
                    },
                    views: {
                        "replaceResource": {
                            template: require("./components/replaceResource/replaceResourceView.html"),
                            controller: "replaceResourceController",
                            controllerAs: "vm"
                        }
                    }
                })
            .state("planboard.upgradeDaymark",
                {
                    url: "/upgradeDaymark/{activityId:int}",
                    params: {
                        activityId: 0
                    },
                    views: {
                        "upgradeDaymark": {
                            template: require("./components/upgradeDaymark/upgradeDaymarkView.html"),
                            controller: "upgradeDaymarkController",
                            controllerAs: "vm"
                        }
                    }
                })
            .state("planboard.customCounterPeriod",
                {
                    url: "/customCounterPeriod",
                    views: {
                        "customCounterPeriod": {
                            template: require("./components/customCounterPeriod/customCounterPeriodView.html"),
                            controller: "customCounterPeriodController",
                            controllerAs: "vm"
                        }
                    }
                })
            .state("planboard.mergeConflicts",
                {
                    url: "/mergeConflicts",
                    views: {
                        "mergeConflicts": {
                            template: require("./components/mergeConflicts/mergeConflictsView.html"),
                            controller: "mergeConflictsController",
                            controllerAs: "vm"
                        }
                    }
                })
            .state("planboard.violations",
                {
                    url: "/violations",
                    views: {
                        "violations": {
                            template: require("./components/violations/violationsView.html"),
                            controller: "violationsController",
                            controllerAs: "vm"
                        }
                    }
                })
            .state("planboard.planningStatus",
                {
                    url: "/planningStatus",
                    views: {
                        "planningStatus": {
                            template: require("./components/planningStatus/planningStatusView.html"),
                            controller: "planningStatusController",
                            controllerAs: "vm"
                        }
                    }
                })
            .state("planboard.resourceCombinations",
                {
                    url: "/resourceCombinations",
                    views: {
                        "resourceCombinations": {
                            template: require("./components/resourceCombinations/resourceCombinationsView.html"),
                            controller: "resourceCombinationsController",
                            controllerAs: "vm"
                        }
                    }
                })
            .state("planboard.changesInPlanning",
                {
                    url: "/changesInPlanning",
                    views: {
                        "changesInPlanning": {
                            template: require("./components/changesInPlanning/changesInPlanningView.html"),
                            controller: "changesInPlanningController",
                            controllerAs: "vm"
                        }
                    }
                })
            .state("userPreferences",
                {
                    url: "/userPreferences",
                    views: {
                        "main": {
                            template: require("./components/userPreferences/userPreferencesView.html"),
                            controller: "userPreferencesController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve,
                        retrieveUserDisplaySettings: retrieveUserDisplaySettingsResolve
                    }
                })
            .state("systemAdministration",
                {
                    url: "/systemAdministration",
                    views: {
                        "main": {
                            template: require("./components/systemAdministration/systemAdministrationView.html"),
                            controller: "systemAdminController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve,
                        retrieveUserDisplaySettings: retrieveUserDisplaySettingsResolve
                    }
                })
            .state("programManagement",
                {
                    url: "/programManagement",
                    views: {
                        "main": {
                            template: require("./components/programManagement/programManagementView.html"),
                            controller: "programManagementController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve,
                        retrieveUserDisplaySettings: retrieveUserDisplaySettingsResolve
                    }
                })
            .state("resourceManagement",
                {
                    url: "/resourceManagement",
                    params: {
                        selectResourceId: 0
                    },
                    views: {
                        "main": {
                            template: require("./components/resourceManagement/resourcesView.html"),
                            controller: "resourcesController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve,
                        retrieveUserDisplaySettings: retrieveUserDisplaySettingsResolve
                    }
                })
            .state("organizationUnits",
                {
                    url: "/organizationUnits",
                    views: {
                        "main": {
                            template: require("./components/programManagement/organizationUnits/organizationUnitsView.html"),
                            controller: "organizationUnitsController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve
                    }
                })
            .state("resourceTypes",
                {
                    url: "/resourceTypes",
                    views: {
                        "main": {
                            template: require("./components/programManagement/resourceTypes/resourceTypesView.html"),
                            controller: "resourceTypesController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve
                    }
                })
            .state("resourceProperties",
                {
                    url: "/resourceProperties",
                    views: {
                        "main": {
                            template: require("./components/programManagement/resourceProperties/resourcePropertiesView.html"),
                            controller: "resourcePropertiesController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve
                    }
                })
            .state("skills",
                {
                    url: "/skillsTypes",
                    views: {
                        "main": {
                            template: require("./components/programManagement/skills/skillsView.html"),
                            controller: "skillsController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve,
                        retrieveUserDisplaySettings: retrieveUserDisplaySettingsResolve
                    }
                })
            .state("skillLevels",
                {
                    url: "/skillLevels",
                    views: {
                        "main": {
                            template: require("./components/programManagement/skillLevels/skillLevelsView.html"),
                            controller: "skillLevelsController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve
                    }
                })
            .state("users",
                {
                    url: "/users",
                    views: {
                        "main": {
                            template: require("./components/programManagement/users/usersView.html"),
                            controller: "usersController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve,
                        retrieveUserDisplaySettings: retrieveUserDisplaySettingsResolve
                    }
                })
            .state("userGroups",
                {
                    url: "/userGroups",
                    views: {
                        "main": {
                            template: require("./components/programManagement/userGroups/userGroupsView.html"),
                            controller: "userGroupsController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve
                    }
                })
            .state("activityTypes",
                {
                    url: "/activityTypes",
                    views: {
                        "main": {
                            template: require("./components/programManagement/activityTypes/activityTypesView.html"),
                            controller: "ActivityTypesCtrl"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve
                    }
                })
            .state("monitoring",
                {
                    url: "/monitoring",
                    views: {
                        "main": {
                            template: require("./components/monitoring/monitoringPageView.html"),
                            controller: "MonitoringPageCtrl"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve
                    }
                })
            .state("monitoring.component",
                {
                    url: "/:componentId",
                    views: {
                        "main@": {
                            template: require("./components/monitoring/monitoringPageComponentView.html"),
                            controller: "MonitoringPageComponentCtrl"
                        },
                        "loginLogoutLink@": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve,
                        componentId: ["$stateParams", function ($stateParams) {
                            return $stateParams.componentId;
                        }]
                    }
                })
            .state("monitoring.component.instance",
                {
                    url: "/:instance",
                    views: {
                        "main@": {
                            template: require("./components/monitoring/monitoringPageInstanceView.html"),
                            controller: "MonitoringPageInstanceCtrl"
                        },
                        "loginLogoutLink@": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve
                    }
                })
            .state("performanceTest",
                {
                    url: "/performanceTest",
                    views: {
                        "main": {
                            template: require("./components/performanceTest/performanceTestView.html"),
                            controller: "PerfTestCtrl"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve
                    }
                })
            .state("scenarioManagement",
                {
                    url: "/scenarioManagement",
                    views: {
                        "main": {
                            template: require("./components/scenarioManagement/scenarioManagementView.html"),
                            controller: "scenarioManagementController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve
                    }
                })
            .state("solverManagement",
                {
                    url: "/solverManagement",
                    views: {
                        "main": {
                            template: require("./components/solverManagement/solverManagementView.html"),
                            controller: "solverManagementController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve,
                        retrieveUserDisplaySettings: retrieveUserDisplaySettingsResolve
                    }
                })
            .state("multiSelect",
                {
                    url: "/multiSelect",
                    views: {
                        "main": {
                            template: require("./components/multiSelect/multiSelectView.html"),
                            controller: "multiSelectController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve,
                        retrieveUserDisplaySettings: retrieveUserDisplaySettingsResolve
                    }
                })
            .state("yearPlanning",
                {
                    url: "/yearPlanning",
                    views: {
                        "main": {
                            template: require("./components/yearPlanning/yearPlanningView.html"),
                            controller: "yearPlanningController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve,
                        retrieveUserDisplaySettings: retrieveUserDisplaySettingsResolve
                    }
                })
            .state("organizationUnitSettings",
                {
                    url: "/organizationUnitSettings",
                    views: {
                        "main": {
                            template: require("./components/programManagement/organizationUnitSettings/organizationUnitSettingsView.html"),
                            controller: "organizationUnitSettingsController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve,
                        retrieveUserDisplaySettings: retrieveUserDisplaySettingsResolve
                    }
                })
            .state("exchangeUpdateConfig",
                {
                    url: "/exchangeUpdateConfig",
                    views: {
                        "main": {
                            template: require("./components/exchangeConfiguration/exchangeUpdateConfigView.html"),
                            controller: "exchangeUpdateConfigurationController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve,
                        retrieveUserDisplaySettings: retrieveUserDisplaySettingsResolve
                    }
                })
            .state("parttimeSchedule",
                {
                    // both methods for parameters are used here for illustration, both in the url and in the params object. Parameters in the url are visible, parameters in the params object are hidden.
                    url: "/parttimeSchedule/{resourceId:int}",
                    params: {
                        resourceId: 0
                    },
                    views: {
                        "main": {
                            template: require("./components/resourceParttimeSchedule/resourceParttimeScheduleView.html"),
                            controller: "resourceParttimeScheduleController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve
                    }
                })
            .state("periodBoundProperties",
                {
                    // both methods for parameters are used here for illustration, both in the url and in the params object. Parameters in the url are visible, parameters in the params object are hidden.
                    url: "/periodBoundProperties/{resourceId:int}",
                    params: {
                        resourceId: 0
                    },
                    views: {
                        "main": {
                            template: require("./components/resourcePeriodBoundProperties/resourcePeriodBoundPropertiesView.html"),
                            controller: "resourcePeriodBoundPropertiesController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve
                    }
                })
            .state("narrowCastingConfiguration",
                {
                    // both methods for parameters are used here for illustration, both in the url and in the params object. Parameters in the url are visible, parameters in the params object are hidden.
                    url: "/narrowCastingConfiguration",
                    views: {
                        "main": {
                            template: require("./components/narrowCastingConfiguration/narrowCastingConfigurationView.html"),
                            controller: "narrowCastingConfigCtrl",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve
                    }
                })
            .state("holidayManagement",
                {
                    // both methods for parameters are used here for illustration, both in the url and in the params object. Parameters in the url are visible, parameters in the params object are hidden.
                    url: "/holidayManagement",
                    views: {
                        "main": {
                            template: require("./components/programManagement/holidays/holidaysView.html"),
                            controller: "holidaysController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve
                    }
                })
            .state("reports",
                {
                    url: "/reports",
                    views: {
                        "main": {
                            template: require("./components/reports/reportsView.html"),
                            controller: "reportsController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve
                    }
                })
            .state("report",
                {
                    url: "/report",
                    params: {
                        uri: ""
                    },
                    views: {
                        "main": {
                            template: require("./components/reports/singleReportView.html"),
                            controller: "singleReportController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve
                    }
                })
            .state("overviews",
                {
                    url: "/overviews",
                    views: {
                        "main": {
                            template: require("./components/overviews/overviewsView.html"),
                            controller: "overviewsController",
                            controllerAs: "vm"
                        },
                        "loginLogoutLink": loginLogoutLinkView
                    },
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve
                    }
                })
            .state("otherwise",
                {
                    url: "*path",
                    views: loginView,
                    resolve: {
                        retrieveTextLabels: retrieveTextLabelsResolve
                    }
                });
    }
];

export var stateconfig = ["$state", "$transitions", "$location", "$http", "authenticationService", "userService", "configurationService", "$window", "$rootScope",
    function (
        $state: StateService,
        $transitions: TransitionService,
        $location: angular.ILocationService,
        $http: angular.IHttpService,
        authenticationService,
        userService,
        configurationService: IConfigurationService,
        $window: Window,
        $rootScope) {

        // Check when routing starts
        $transitions.onStart({ to: '*' }, (transition) => {
            // If we are authenticated and the new state is otherwise, then forward to the portal
            if (userService.isAuthenticated && transition.to().name === "otherwise" && userService.isFullUser) {
                return transition.router.stateService.target("portal");
            } 

            // If we are authenticated and are a resource-only user and the state is portal, then redirect to the correct reports view
            if (userService.isAuthenticated && (userService.isFullUser == null || userService.isFullUser === false)) {
                if (transition.from().name === "reports") {
                    transition.router.stateService.target("portal");
                } else {
                    configurationService.getAppConfiguration(() => {
                        if (configurationService.appConfiguration.featureManagement.ssrsReports && userService.reportsPageForResourceUserSsrs != null) {
                            $window.location.href = userService.reportsPageForResourceUserSsrs;
                        }
                        else {
                            $window.location.href = "angularomrp/overviews/schedule-overview";
                        }
                    });
                }
                return;
            }

            if (transition.from() != null && transition.to() != null && !transition.from().abstract && !transition.to().abstract) {
                // if we move from a resourceManagement page to a non resourceManagement page, then delete saved variables for those pages
                if (userService.isResourceManagementState(transition.from().name) &&
                    !userService.isResourceManagementState(transition.to().name))
                    userService.deleteUserVariables("resources.");
            }

            // If we are authenticated, or the new state is either login or saveSSOToken then we do not have to do anything
            if (userService.isAuthenticated || (transition.to() != null && ["login", "authorize", "saveSSOToken"].indexOf(transition.to().name) !== -1)) return;

            // ask WebApi if we are authenticated
            $http.get("api/GetUserInfo")
                .then(function (response) {
                    if (!authenticationService.reconnect(response.data)) {
                        return authenticationService.redirectToLoginPage();
                    } else if (userService.isFullUser !== null && userService.isFullUser === false) {
                        configurationService.getAppConfiguration(() => {
                            if (configurationService.appConfiguration.featureManagement.ssrsReports && userService.reportsPageForResourceUserSsrs != null) {
                                $window.location.href = userService.reportsPageForResourceUserSsrs;
                            }
                            else {
                                $window.location.href = "angularomrp/overviews/schedule-overview";
                            }
                        });
                    } else if ($state.current.abstract) {
                        // abstract state, try to reconstruct from locations path, if this fails then $stateNotFound should trigger (see below).
                        var newToState = $location.path().substring(1); // remove the / at the start
                        if ($state.href(newToState) == null && userService.isFullUser) {
                            newToState = "portal"; // test if the state exist, else go to portal

                            console.log("Redirecting from abstract state to: " + newToState);
                            return transition.router.stateService.target(newToState);
                        }
                    }
                });

            authenticationService.scheduleRefreshToken();
        });

        $transitions.onError({ to: '*' }, (transition) => {
            console.log("Transition error: " + transition.error().message);
        });
    }];

// Fire a documentClicked event when the user clicks on the document. Any angular directive that listens can use this, for example to hide dropdowns or popups.
export var clickconfig = ["$rootScope",
    function ($rootScope) {
        angular.element(document)
            .on("click", function (e) { $rootScope.$broadcast("documentClicked", angular.element(e.target)); });

        $(window).on("resize.doResizeWindow", function () {
            $rootScope.$broadcast("documentClicked", angular.element(document));
        });

        $(window).on("scroll.doScrollWindow", function () {
            $rootScope.$broadcast("documentClicked", angular.element(document));
        });
    }
];

// Tell the dynamic locale provider where to look for locale files.
export var localeconfig = ["tmhDynamicLocaleProvider", "environmentProvider",
    function (tmhDynamicLocaleProvider, environment: IEnvironmentProvider) {
        const relativeLocationPattern = '/omrp/Scripts/i18n/angular-locale_{{locale}}.js';
        const locationPattern = environment.baseUrl ? environment.baseUrl + relativeLocationPattern : relativeLocationPattern;
        tmhDynamicLocaleProvider.localeLocationPattern(locationPattern);
    }
];

export var auth0config = [
    "angularAuth0Provider", function (angularAuth0Provider) {
        angularAuth0Provider.init({
            clientID: Constants.auth0ClientId,
            domain: Constants.auth0Domain,
            responseType: 'code',
            redirectUri: Constants.auth0RedirectUri,
            scope: Constants.auth0Scope
        } as AuthOptions);
    }
];

// Push the interceptor to add tokens to the messages if available, and reroute to the login page otherwise
export var authinterceptorconfig = [
    "$httpProvider",
    function ($httpProvider) {
        $httpProvider.interceptors.push("authInterceptorService");
        $httpProvider.interceptors.push("tokenExpiredInterceptorService");
    }
];