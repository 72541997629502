import { ITranslationService } from './../i18n/translationService';
import { ITreeListScope } from './../treeListController/ITreeListScope';
import { IConfigurationService } from "./../../shared/configurationService";

export const indexPage: angular.IComponentOptions = {
    template: require("./indexPage.html"),
    controller: indexPageController
}

indexPageController.$inject = [
    "$scope",
    "$rootScope",
    "translationService",
    "configurationService",
    "$document"
];

function indexPageController(
    $scope: ITreeListScope,
    $rootScope: ng.IRootScopeService,
    translationService: ITranslationService,
    configurationService: IConfigurationService,
    $document
) {
    this.$scope = $scope;
    translationService.getTextLabels($scope);
    this.showRedTestBorder = false;

    $rootScope.$on("severeError", (event, data) => {
        this.severeErrorTitle = data.errorTitle;
        this.severeErrorMessage = data.errorText;
        $("#severeError").modal("toggle");
    });

    $rootScope.$on("isUserLoggedIn", (event, data) => {
        if (data.isUserLoggedIn) {
            configurationService.getAppConfiguration(() => {
                this.showRedTestBorder = String(configurationService.appConfiguration.featureManagement.featureEnableTestBorder).toLowerCase() === "true";
            });
        }
    });
}