export interface IConfigurationService {
    limitSettings;
    appConfiguration: AppConfiguration;

    /**
        * get limit settings from Web.config
        */
    getLimitSettings(callback: () => void): void;

    getAppConfiguration(callback: () => void, failedCallbackFunction?: () => void): void;
}

export var configurationService = [
    "$http", "$timeout",
    function ($http, $timeout) {
        var svc = this;
        svc.limitSettings = null;
        svc.getAppConfiguration = null;

        svc.getLimitSettings = (callbackFunction) => {
            if (svc.limitSettings != null) {
                callbackFunction();
                return;
            }

            $http.get("api/Configuration/GetLimitSettings")
                .then((response) => {
                    svc.limitSettings = response.data;

                    // calling the callback function inside a $timeout to trigger a digest cycle
                    $timeout(() => {
                        callbackFunction();
                    });
                });
        }

        svc.getAppConfiguration = (callbackFunction, failedCallbackFunction) => {
            if (svc.appConfiguration != null) {
                callbackFunction();
                return;
            }

            $http.get("api/Configuration")
                .then((response) => {
                    svc.appConfiguration = convertFromDto(response.data as AppConfigurationDto);

                    // calling the callback function inside a $timeout to trigger a digest cycle
                    $timeout(() => {
                        callbackFunction();
                    });
                },
                () => {
                    if (failedCallbackFunction) {
                        // calling the callback function inside a $timeout to trigger a digest cycle
                        $timeout(() => {
                            failedCallbackFunction();
                        });
                    }
                });
        }
    }
];

export function convertFromDto(data: AppConfigurationDto): AppConfiguration {
    const result: AppConfiguration = data;

    return result;
}

export class AppConfiguration {
    featureManagement: FeatureManagement;
    planboardSettings: PlanboardSettings;
    environmentSettings: EnvironmentSettings;
}

export class FeatureManagement {
    countersOverview: boolean;
    ssrsReports: boolean;
    performanceImprovementsJune2021: boolean;
    useActivityProcessedTable: boolean;
    useAngularScreens: boolean;
    featureActivityDemand: boolean;
    featureDisableMonitoringPage: boolean;
    featureEnableTestBorder: boolean;
    featureEnableOWS: boolean;
    featureEnableScheduleOverview: boolean;
}

export class PlanboardSettings {
    batchBroadcastCooldown: number;
    teamSchedulerAppUrl: string;
}


export class EnvironmentSettings {
    isNonProductionEnvironment: string;
}

class AppConfigurationDto {
    featureManagement: FeatureManagementDto;
    planboardSettings: PlanboardSettingsDto;
    environmentSettings: EnvironmentSettingsDto;
}

class FeatureManagementDto {
    countersOverview: boolean;
    ssrsReports: boolean;
    performanceImprovementsJune2021: boolean;
    useActivityProcessedTable: boolean;
    useAngularScreens: boolean;
    featurePlanboardAPlanningBoard: boolean;
    featureActivityDemand: boolean;
    featureDisableMonitoringPage: boolean;
    featureEnableTestBorder: boolean;
    featureEnableOWS: boolean;
    featureEnableScheduleOverview: boolean;
}

class PlanboardSettingsDto {
    batchBroadcastCooldown: number;
    teamSchedulerAppUrl: string
}

class EnvironmentSettingsDto {
    isNonProductionEnvironment: string;
}