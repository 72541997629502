import { StateService } from "@uirouter/angularjs";
import { IPageStartService } from "./../../shared/pageStartService";
import { IUserService } from "./../../shared/userService";

import { ITranslationService } from "./../i18n/translationService";

import { INotificationService } from "./../login/notificationService";

import { IPermissionService } from "./../permissions/permissionService";

import { ITreeListScope } from "./../treeListController/ITreeListScope";

import { IModalConfirmationWindowService } from "./../utils/modalConfirmationWindowService";
import { IConfigurationService, FeatureManagement } from "./../../shared/configurationService";


export class PortalPageController {
    reportsAvailable: boolean;
    versionInfo: any;
    featureManagement: FeatureManagement;
    featureActivityDemand: boolean = false;
    pageFinishedLoading: boolean = false;
    featureEnableScheduleOverview: boolean = false;

    private commonSvc: any;
    private verificationStatus: any = {
        pending: true, // to prevent screen flickering
        failed: false,
        hasPermission: true
    };
    private userPermissions: any = { granted: [] };
    private enabledFeatures: any[] = [];

    private readonly dialogToken: string = "portal";
    private readonly portalEvent: string = "portalEvent";

    static $inject = [
        "$scope",
        "$state",
        "configurationService",
        "modalConfirmationWindowService",
        "notificationService",
        "pageStartService",
        "permissionService",
        "translationService",
        "userService",
        "$q",
        "$window"
    ];

    constructor(
        public $scope: ITreeListScope,
        private readonly $state: StateService,
        private readonly configurationService: IConfigurationService,
        private readonly modalConfirmationWindowService: IModalConfirmationWindowService,
        private readonly notificationService: INotificationService,
        private readonly pageStartService: IPageStartService,
        private readonly permissionService: IPermissionService,
        private readonly translationService: ITranslationService,
        private readonly userService: IUserService,
        private readonly $q: ng.IQService,
        private readonly $window: Window
    ) {
        const deferred = $q.defer();
        const getReportsUrisPromise = deferred.promise;

        this.translationService.getTextLabels(this.$scope);
        this.commonSvc = this.pageStartService.initialize(this.$scope, null, this.dialogToken);

        // remove the latest breadcrumb variables
        this.userService.deleteUserVariable("preferences.breadcrumbState");
        this.userService.deleteUserVariable("preferences.breadcrumbText");

        this.configurationService.getAppConfiguration(() => {
            this.featureManagement = this.configurationService.appConfiguration.featureManagement;

            if (!this.featureManagement.ssrsReports) {
                deferred.resolve();
            } else {
                // See if a reports base uri is available, only show the reports button if that is indeed the case.
                this.commonSvc.loadData("api/Reports/Uris", null, (response) => {
                    this.reportsAvailable = response.data && response.data.length > 0;
                    deferred.resolve();
                }, null, true, false);
            }

            if (this.featureManagement.featureActivityDemand) {
                this.featureActivityDemand = true;
            }

            if (this.featureManagement.featureEnableScheduleOverview) {
                this.featureEnableScheduleOverview = true;
            }
        });

        const getPermissionsPromise = this.permissionService.getAllPermissions(this.userPermissions, this.verificationStatus, this.$scope);

        $q.all([getPermissionsPromise, getReportsUrisPromise]).then(() => {
            this.pageFinishedLoading = true;

            this.$scope.$on("$destroy",
                () => {
                    this.notificationService.removeStringFromClientInfo(this.portalEvent);
                });

            this.notificationService
                .subscribeToWebSocketEvent(this.$scope,
                    this.portalEvent,
                    () => {
                        this.userService.refreshPage();
                    });

            this.notificationService.addStringToClientInfo(this.portalEvent);
        });
    }

    userHasPermission(permissionName) {
        return this.userPermissions.granted.indexOf(permissionName) > -1;
    }

    userHasAnyProgramManagementPermissions() {
        return this.userHasPermission("OrganizationUnits") ||
            this.userHasPermission("ResourceTypes") ||
            this.userHasPermission("ResourceProperties") ||
            this.userHasPermission("Skills") ||
            this.userHasPermission("SkillLevels") ||
            this.userHasPermission("Users") ||
            this.userHasPermission("UserGroups") ||
            this.userHasPermission("ActivityTypes") ||
            this.userHasPermission("ExchangeInterface") ||
            this.userHasPermission("ManageHolidays");
    }

    userHasAnySystemManagementPermissions() {
        return this.userHasPermission("Monitoring") ||
            this.userHasPermission("ExchangeInterface");
    }

    openPlanboard() {
        this.$state.transitionTo("planboard", {});
    }

    openSystemAdministration() {
        this.$state.transitionTo("systemAdministration", {});
    }

    openProgramManagement() {
        this.$state.transitionTo("programManagement", {});
    }

    openActivityDemand() {
        this.$window.location.href = "/angularomrp/activity-demand";
    }

    activityDemandFeatureFlag() {
        return this.featureActivityDemand;
    }

    openResourceManagement() {
        this.$state.transitionTo("resourceManagement", {});
    }

    openPerformanceTest() {
        this.$state.transitionTo("performanceTest", {});
    }

    openScenarioManagement() {
        this.$state.transitionTo("scenarioManagement", {});
    }

    openYearPlanning() {
        this.$state.transitionTo("yearPlanning", {});
    }

    openOverviews() {
        this.$state.transitionTo("overviews", {});
    }

    showVersionInfo() {
        this.commonSvc.loadData("api/ApplicationInfo/Version",
            null,
            (response) => {
                this.versionInfo = response.data;
                this.modalConfirmationWindowService.showModalInfoDialog(this.versionInfo.productName,
                    this.versionInfo.majorReleaseName +
                    " (" +
                    this.versionInfo.assemblyVersion +
                    ")\n" +
                    this.versionInfo.copyrightInfo,
                    this.$scope.textLabels.OK,
                    null,
                    0,
                    this.portalEvent);
            },
            null,
            true);
    }
}
